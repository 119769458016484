<template>
  <div class="product_tab_table c_wrapper">
    <div class="product_tab_wrapper">
      <h2 class="c_header c_header--6 c_text--margin--bottom" v-if="tab.tabHeader" v-html="tab.tabHeader" />
      <div class="c_table">
        <table v-if="table.length">
          <tr v-for="(row, index) in table" :key="index">
            <td
              class="product_tab_table__cell"
              :class="[
                column.type ? `product_tab_table--${column.type}` : null,
                column.type === 'disclosure' ? 'c_text' : null,
              ]"
              :colspan="column.colspan"
              v-for="(column, columnIndex) in row"
              :key="columnIndex"
              v-html="column.data"
            ></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from '@nuxtjs/composition-api';
import { log } from 'JS/global.js';

export default defineComponent({
  name: 'ProductTabMultiColumnTable',
  props: {
    tabData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      tab: {},
      table: [],
    });

    state.tab = props.tabData;

    if (state.tab.multiColumnTable) {
      state.tab.multiColumnTable.forEach((block) => {
        switch (block.typeHandle) {
          case 'twoColumnRows':
            block.table.forEach((tableRow) => {
              state.table.push([
                { colspan: 1, data: tableRow.column1 },
                { colspan: 4, data: tableRow.column2 },
              ]);
            });
            break;
          case 'threeColumnRows':
            block.table.forEach((tableRow) => {
              state.table.push([
                { colspan: 1, data: tableRow.column1 },
                { colspan: 2, data: tableRow.column2 },
                { colspan: 2, data: tableRow.column3 },
              ]);
            });
            break;
          case 'fiveColumnRows':
            block.labels.forEach((tableRow) => {
              state.table.push([
                { colspan: 1, data: '' },
                { colspan: 2, data: tableRow.label1, type: 'center' },
                { colspan: 2, data: tableRow.label2, type: 'center' },
              ]);
            });
            block.table.forEach((tableRow) => {
              state.table.push([
                { colspan: 1, data: tableRow.column1 },
                { colspan: 1, data: tableRow.column2 },
                { colspan: 1, data: tableRow.column3 },
                { colspan: 1, data: tableRow.column4 },
                { colspan: 1, data: tableRow.column5 },
              ]);
            });
            break;
          case 'header':
            state.table.push([{ colspan: 5, data: block.text, type: 'header' }]);
            break;
          case 'disclosureRow':
            state.table.push([{ colspan: 5, data: block.information, type: 'disclosure' }]);
            break;
        }
      });
    }

    return { ...toRefs(state) };
  },
  mounted() {
    log('ProductTabMultiColumnTable loaded');
  },
});
</script>

<style lang="scss">
.product_tab_table {
  $self: &;
  --table-label-width: 300px;
  --table-min-width: 1000px;

  @include mq_max($mq_sm_to_md) {
    --wrapper-padding: 0;
  }

  @at-root #{$self}--center {
    text-align: center;
  }
  @at-root #{$self}--header {
    @include font('graphik_medium');
    font-size: 1.45rem;
  }
  @at-root #{$self}--disclosure {
    padding: 14px;
  }

  @at-root #{$self}__cell {
    & > * {
      --text-fz: 1rem;
      --text-mb: 0.3rem;
    }
  }
}
</style>
