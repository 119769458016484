<template>
  <div class="product_tab_specifications c_wrapper">
    <div class="product_tab_wrapper">
      <h2 class="c_header c_header--6 c_text--margin--bottom" v-if="tab.tabHeader" v-html="tab.tabHeader" />
      <mq-layout mq="sm" v-if="tab.simpleTable">
        <AccordionTab
          mobile
          toggle-self
          v-for="(row, index) in tab.simpleTable"
          :key="index"
          v-if="row.typeHandle === 'row'"
        >
          <div slot="header" v-html="row.header"></div>
          <div slot="content" v-html="row.information"></div>
        </AccordionTab>
        <div class="product_tab_specifications__disclosure" v-html="row.information" v-else />
      </mq-layout>
      <mq-layout mq="md+" v-if="tab.simpleTable">
        <div class="c_table c_table--column_borders">
          <table>
            <tr v-for="(row, index) in tab.simpleTable" :key="index" v-if="row.typeHandle === 'row'">
              <td v-html="row.header" />
              <td v-html="row.information" />
            </tr>
            <tr v-else>
              <td colspan="2" v-html="row.information" />
            </tr>
          </table>
        </div>
      </mq-layout>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from '@nuxtjs/composition-api';
import { log } from 'JS/global.js';
import AccordionTab from 'Components/accordion/AccordionTab.vue';

export default defineComponent({
  name: 'ProductTabTable',
  components: {
    AccordionTab,
  },
  props: {
    tabData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      tab: {},
    });

    state.tab = props.tabData;

    return { ...toRefs(state) };
  },
  mounted() {
    log('ProductTabTable loaded');
  },
});
</script>

<style lang="scss">
.product_tab_specifications {
  $self: &;
  --table-label-width: 184px;

  @include mq_max($mq_sm_to_md) {
    --wrapper-padding: 0;
  }

  @at-root #{$self}__disclosure {
    padding: 14px;
  }
}
</style>
