<template>
  <a v-if="hasId" :id="slugifiedId" aria-label="Section anchor link" aria-hidden="true"></a>
</template>

<script>
import { slugify } from 'JS/global.js';

export default {
  props: {
    id: String,
  },
  data() {
    return {
      slugifiedId: '',
      hasId: true,
    };
  },
  created() {
    // If section block has a title associated with it, slugify it and create a relative anchor link to be used by the Header sidebar quicklinks. Otherwise don't assign an ID, and don't render the relative anchor link.
    this.id ? (this.slugifiedId = slugify(this.id)) : (this.hasId = false);
  },
};
</script>
