<template>
  <div class="c_related_resources__filter">
    <ul>
      <li
        v-for="(resourceType, index) in types"
        :key="index"
        :class="{ 'c_related_resources__item--active': index === activeType }"
      >
        <Button @onClick="filterResources(resourceType.slug, index)" :aria-label="'Filter by ' + resourceType.title">
          {{ resourceType.title }}
        </Button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    types: Array,
    activeType: Number,
  },
  components: {
    Button: () => import('Components/button/Button.vue'),
  },
  methods: {
    filterResources(slug, i) {
      this.$emit('resourcesFiltered', slug, i);
    },
  },
};
</script>

<style lang="scss">
.c_related_resources {
  $self: &;
  @at-root #{&}__filter {
    button {
      margin: px_to_rem(20) 0 0;
      padding: 0;
      background-color: transparent;
      border-top: none;
      border-right: none;
      border-bottom: 4px solid transparent;
      border-left: none;
      outline: none;
      font-size: px_to_rem(18);
      line-height: px_to_rem(21);
      @include font('graphik_regular');
      color: $color_black;
    }
    ul {
      margin-bottom: px_to_rem(40);
      li {
        display: block;
        @at-root #{$self}__item--active button {
          border-bottom: 4px solid $color_yellow;
          @include font('graphik_semibold');
        }
        @include mq($mq_sm_to_md) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    @include mq($mq_sm_to_md) {
      button {
        margin: 0;
        padding: px_to_rem(12) px_to_rem(30);
      }
      ul {
        display: flex;
        justify-content: center;
        margin-bottom: px_to_rem(50);
        li {
          margin-left: px_to_rem(10);
          margin-right: px_to_rem(10);
        }
      }
    }
  }
}
</style>
