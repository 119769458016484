<template>
  <div class="c_resources">
    <div class="c_resources__item" v-for="(resource, index) in resources" :key="index">
      <AssetButton
        v-if="resource.videoId && resource.videoSource == 'youtube'"
        :href="`https://youtu.be/${resource.videoId}`"
        :icon-options="{ handle: 'video_camera' }"
      >
        {{ resource.title }}
      </AssetButton>
      <AssetButton
        v-else-if="resource.videoId && resource.videoSource == 'youku'"
        :href="`https://v.youku.com/v_show/id_${resource.videoId}`"
        :icon-options="{ handle: 'video_camera' }"
      >
        {{ resource.title }}
      </AssetButton>
      <AssetButton v-else-if="resource.url" :href="resource.url" :icon-options="{ handle: 'file_download' }">
        {{ resource.title }}
      </AssetButton>
    </div>
  </div>
</template>

<script>
import AssetButton from 'Components/asset_button/AssetButton.vue';
export default {
  components: {
    AssetButton,
  },
  data() {
    return {};
  },
  props: {
    resources: {
      type: Array,
      required: true,
    },
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style lang="scss">
// @import './_source/_css/automated/_colors';
// @import './_source/_css/automated/_fonts';
// @import './_source/_css/base/_variables.scss';
// @import './_source/_css/base/_mixins.scss';

.c_resources {
  $self: &;

  display: block;
  @include mq($mq_sm_to_md, '', 'rem') {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: px_to_rem(54);

    @at-root .app--isEdge15orDown & {
      display: flex;
      flex-flow: row wrap;
      & > * {
        flex: 0 1 50%;
      }
    }
  }
  @include mq(850px, '', 'rem') {
    grid-template-columns: repeat(3, 1fr);

    @at-root .app--isEdge15orDown & {
      & > * {
        flex: 0 1 33%;
      }
    }
  }
  @at-root #{&}__item {
    padding: px_to_rem(10) 0 px_to_rem(8);
    border-bottom: 2px solid $color_gray_235;
    &:last-child {
      border: 0;
    }
    @include mq($mq_sm_to_md, '', 'rem') {
      padding: 0;
      border: 0;
    }
  }
}
</style>
