<template>
  <section class="c_wrapper" ref="c_chart_2022">
    <RelativeAnchor :id="data.pageTitle" />
    <div class="c_chart">
      <div class="c_chart__header" v-if="data.pageTitle">
        <h3 class="c_2022--h3_graphik">{{ data.pageTitle }}</h3>
        <IconSVG width="57" height="29" handle="chevron_down" aria-hidden="true"></IconSVG>
      </div>
      <div
        class="c_chart__content"
        v-if="data.chartColumn.length"
        :class="{
          'c_chart__content--multi': data.chartColumn.length > 1,
          'c_chart__content--two_columns': divisibleByTwo,
        }"
      >
        <div class="c_chart__item" v-for="(column, index) in data.chartColumn" :key="index">
          <h4 class="c_2022--body" v-if="column.columnHeading">{{ column.columnHeading }}</h4>
          <div class="c_2022--body" v-if="column.columnContent" v-html="column.columnContent"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { openPdfsInNewTab } from 'JS/global.js';
import IconSVG from 'Components/icon_svg/IconSVG.vue';
import RelativeAnchor from 'Components/relative_anchor/RelativeAnchor.vue';

export default {
  components: {
    IconSVG,
    RelativeAnchor,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      blockCount: null,
      divisibleByTwo: false,
    };
  },
  created() {
    this.blockCount = this.data.chartColumn.length;
    // Display chart as 2 column when number of blocks is 2 or 4
    this.blockCount % 2 === 0 && this.blockCount < 5 ? (this.divisibleByTwo = true) : (this.divisibleByTwo = false);
  },
  mounted() {
    openPdfsInNewTab(this.$refs?.c_chart_2022);
  },
};
</script>

<style lang="scss">
.c_chart {
  $self: &;
  background-color: $color_black;
  color: $color_white;
  margin: px_to_rem(40) 0;
  @include mq($mq_md_to_lg) {
    margin: px_to_rem(60) 0;
  }
  @at-root #{&}__header {
    background-color: $color_yellow;
    color: $color_yellow;
    text-align: center;
    position: relative;
    padding: px_to_rem(24);
    h3 {
      color: $color_black;
      @include font('graphik_bold');
    }
    .c_icon_svg {
      position: absolute;
      bottom: px_to_rem(-25);
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  @at-root #{&}__content {
    @include mq($mq_md_to_lg) {
      border-bottom: 1px solid black;
      border-left: 1px solid black;
    }
    & > div {
      padding: px_to_rem(40) px_to_rem(23) px_to_rem(40);
      @include mq($mq_md_to_lg) {
        padding: px_to_rem(60) px_to_rem(32) px_to_rem(40);
      }
    }
    h4 {
      @include font('graphik_bold');
      margin-bottom: px_to_rem(18);
    }
    p {
      margin-bottom: px_to_rem(10);
    }
    strong {
      @include font('graphik_semibold');
    }
    ul {
      padding-left: px_to_rem(20);
      li {
        list-style-type: disc;
      }
    }
    ol {
      padding-left: px_to_rem(20);
      li {
        list-style-type: decimal;
      }
    }
    a,
    a:visited {
      color: $color_red;
      &:hover {
        text-decoration: none;
      }
    }
    @at-root #{&}--multi {
      display: block;
      @include mq($mq_md_to_lg) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
      & > div {
        border-bottom: px_to_rem(1) solid $color_gray_112;
        @include mq($mq_md_to_lg) {
          border-bottom: px_to_rem(1) solid $color_gray_112;
          border-right: px_to_rem(1) solid $color_gray_112;
        }
      }
    }
    @at-root #{&}--two_columns {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>
