<template>
  <div class="product_tab_resources c_wrapper c_box--width">
    <h2 class="c_header c_header--6 c_text--margin--bottom" v-if="tab.tabHeader" v-html="tab.tabHeader" />
    <AccordionTab
      v-if="tab.resourcesTab"
      v-for="(block, index) in tab.resourcesTab"
      :key="index"
      :mobile="$mq == 'sm'"
      :toggle-self="$mq == 'sm'"
      :disabled="!block.header || $mq != 'sm'"
    >
      <div v-if="block.header" slot="header">{{ block.header }}</div>
      <div slot="content">
        <div v-if="block.typeHandle == 'resources'" class="product_tab_resources__resources">
          <h3 v-if="block.header && $mq != 'sm'" class="c_header--5 c_text--uppercase c_header--underline">
            {{ block.header }}
          </h3>
          <Resources :resources="block.resources"></Resources>
        </div>
      </div>
    </AccordionTab>
    <AccordionTab
      v-if="resourcesVideosImages.length"
      class="product_tab_resources__videos"
      :mobile="$mq == 'sm'"
      :toggle-self="$mq == 'sm'"
      :disabled="!tab.resourcesVideoHeader || $mq != 'sm'"
    >
      <div v-if="tab.resourcesVideoHeader" slot="header">{{ tab.resourcesVideoHeader }}</div>
      <div slot="content">
        <h3
          v-if="tab.resourcesVideoHeader && $mq != 'sm'"
          class="c_header--5 c_text--uppercase c_header--underline c_header--underline--gray_235 product_tab_resources__videos__header"
        >
          <span class="c_header--underline--yellow">{{ tab.resourcesVideoHeader }}</span>
        </h3>
        <Slider
          rounded
          show-indicators
          show-thumbnails
          video-player
          :arrows="$mq | mq({ sm: 'inside', md: 'outside' })"
          :main-height="$mq | mq({ sm: '350px', md: '750px' })"
          :slide-image-sources="resourcesVideosImages"
        />
      </div>
    </AccordionTab>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from '@nuxtjs/composition-api';
import { log } from 'JS/global.js';
import AccordionTab from 'Components/accordion/AccordionTab.vue';
import Resources from 'Components/resources/Resources.vue';
import Slider from 'Components/slider/Slider.vue';

export default defineComponent({
  name: 'ProductTabResources',
  components: {
    AccordionTab,
    Resources,
    Slider,
  },
  props: {
    tabData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      tab: {},
      resourcesVideosImages: [],
    });

    state.tab = props.tabData;

    if (state.tab.resourcesVideos) {
      state.resourcesVideosImages = [];
      state.tab.resourcesVideos.forEach((item) => {
        state.resourcesVideosImages.push({
          sourcesMain: [
            { srcset: item.galleryEnlarged.srcsetWebp, type: 'image/webp' },
            { srcset: item.galleryEnlarged.srcset },
          ],
          sourcesThumbnails: [
            { srcset: item.galleryThumbnail.srcsetWebp, type: 'image/webp' },
            { srcset: item.galleryThumbnail.srcset },
          ],
          videoId: item.videoId || false,
          videoSource: item.videoSource || false,
          alt: item.title,
        });
      });
    }

    return { ...toRefs(state) };
  },
  methods: {
    onOpenReloadSlider(thisAccordionTab) {
      for (let i = 0; i < thisAccordionTab.$children.length; i++) {
        if ('galleryGridSlider' in thisAccordionTab.$children[i].$refs) {
          thisAccordionTab.$children[i].reload();
        } else if (
          'main' in thisAccordionTab.$children[i].$refs &&
          'thumbnails' in thisAccordionTab.$children[i].$refs
        ) {
          thisAccordionTab.$children[i].reloadAll();
        } else if ('main' in thisAccordionTab.$children[i].$refs) {
          thisAccordionTab.$children[i].reloadMain();
        } else if ('thumbnails' in thisAccordionTab.$children[i].$refs) {
          thisAccordionTab.$children[i].reloadThumbnails();
        }
      }
    },
  },
  mounted() {
    log('ProductTabResources loaded');
  },
});
</script>

<style lang="scss">
.product_tab_resources {
  $self: &;

  @include mq_max($mq_sm_to_md) {
    --wrapper-padding: 0;
  }
  @at-root #{$self}__resources {
    @include mq($mq_sm_to_md, '', 'rem') {
      padding-bottom: px_to_rem(63);
    }
  }
  @at-root #{$self}__videos {
    padding-top: px_to_rem(20);
    .c_accordion_tab--disabled & {
      padding-top: px_to_rem(40);
    }
    .c_accordion_tab--first & {
      padding-top: 0;
    }
    .c_accordion_tab--last & {
      padding-bottom: 0;
    }
    @at-root #{&}__header {
      margin-bottom: px_to_rem(60);
    }
  }
}
</style>
