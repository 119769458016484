<template>
  <section class="c_box--gray_240" ref="c_text_and_content_cards">
    <RelativeAnchor :id="data.pageTitle" />
    <div class="c_wrapper_2022" v-if="data.pageTitle">
      <h2 class="c_2022--h2_graphik">{{ data.pageTitle }}</h2>
    </div>
    <div class="c_wrapper_2022 c_card_2022__container c_grid lp__block--basicText--3_column c_grid--2-3">
      <figure
        v-for="(card, index) in data.cardAssets"
        :key="index"
        class="c_card_2022"
        :class="{ 'c_card_2022--rounded_corners': data.roundedCorners }"
      >
        <div
          class="c_card_2022__img_container"
          :class="{ 'c_card_2022__img_container--fixedHeight': !data.moviePosters }"
        >
          <MediaImage v-if="card.lpImageTextUncropped" :sources="[card.lpImageTextUncropped]" :alt="card.altText" />
        </div>
        <figcaption class="c_card_2022__text_container">
          <h2 class="c_card_2022__title c_2022--h2_graphik">
            {{ card.cardTitle }}
          </h2>
          <span class="c_card_2022__description c_2022--body" v-html="card.cardDescription"></span>
          <ul class="c_card_2022__matrix_links">
            <li v-for="(link, linkIndex) in cardLinksMatrix(card.linksMatrix)" :key="linkIndex">
              <LinkField
                class="c_text--graphik_semibold c_2022--body"
                :matrix-field="link"
                :button-attrs="getLinkAttrs()"
              />
            </li>
          </ul>
        </figcaption>
      </figure>
    </div>
  </section>
</template>

<script>
import { parseLinkField, openPdfsInNewTab } from 'JS/global.js';
import MediaImage from 'Components/image/MediaImage.vue';
import LinkField from 'Components/link_field/LinkField.vue';

export default {
  components: {
    LinkField,
    RelativeAnchor: () => import('Components/relative_anchor/RelativeAnchor.vue'),
    MediaImage,
  },
  props: {
    data: Object,
  },
  methods: {
    isLinkCta(style) {
      return style === 'ctasDisplayAsLinks';
    },
    getLinkAttrs() {
      return {
        arrow: false,
        reset: true,
        unstyle: true,
        outline: false,
      };
    },
    cardLinksMatrix(linksMatrix) {
      if (linksMatrix?.length) {
        return linksMatrix.filter((link) => {
          const href = link.element?.[0]?.uri || link.urlText || '';
          if (href.includes('.pdf') || linksMatrix.openInANewWindow === true) {
            link.openInANewWindow = href;
          }
          return parseLinkField(link, 'matrix').valid;
        });
      }
      return [];
    },
  },
  mounted() {
    openPdfsInNewTab(this.$refs.c_text_and_content_cards);
  },
};
</script>

<style lang="scss">
.c_card_2022__container {
  $_transition: all 0.2s ease-in-out;

  padding: px_to_rem(25) var(--wrapper-padding-right, var(--wrapper-padding, 5vw)) px_to_rem(25)
    var(--wrapper-padding-left, var(--wrapper-padding, 5vw));
  @include mq($mq_md_to_lg) {
    padding: px_to_rem(50) var(--wrapper-padding-right, var(--wrapper-padding, 5vw)) px_to_rem(50)
      var(--wrapper-padding-left, var(--wrapper-padding, 5vw));
  }
  .c_card_2022 {
    $self: &;
    background-color: $color_white;
    display: flex;
    flex-direction: column;
    @at-root #{&}__img_container {
      overflow: hidden;
      figure {
        height: 100%;
        picture {
          display: block;
          height: 100%;
          img {
            display: block;
            object-fit: cover;
            object-position: center;
            height: 100%;
          }
        }
      }
      @at-root #{&}--fixedHeight {
        @include mq($mq_sm_to_md) {
          max-height: px_to_rem(450);
          height: 36vw;
          min-height: 24rem;
        }
        @include mq($mq_md_to_lg) {
          min-height: 28rem;
        }
      }
    }
    @at-root #{&}__text_container {
      padding: px_to_rem(20);
      background-color: $color_white;
      display: flex;
      flex-direction: column;
      flex: 1;
      @include mq($mq_md_to_lg) {
        padding: px_to_rem(30) px_to_rem(38) px_to_rem(30) px_to_rem(33);
      }
      span {
        display: block;
      }
      @at-root #{$self}__title {
        @include font('graphik_medium');
        margin-bottom: px_to_rem(10);
        @include mq($mq_md_to_lg) {
          margin-bottom: px_to_rem(25);
        }
      }
      @at-root #{$self}__description {
        margin-bottom: px_to_rem(25);
        p {
          margin-bottom: px_to_rem(10);
        }
        a {
          color: $color_red;
          text-decoration: underline;
          @include font('graphik_regular');
          &:visited,
          &:focus {
            color: $color_red;
          }
          &:hover {
            text-decoration: none;
          }
        }
        ul {
          list-style-type: disc;
          padding-left: px_to_rem(15);
          margin-bottom: px_to_rem(10);
        }
        ol {
          padding-left: px_to_rem(15);
          margin-bottom: px_to_rem(10);
        }
      }
      @at-root #{$self}__matrix_links {
        margin-top: auto;
      }
      .c_link_field {
        text-decoration: underline;
        margin-bottom: px_to_rem(10);
        display: inline-block;
        @include font('graphik_regular');
        @include fs_lh(18, 22.5, 16, 20);
        color: $color_red;
        &:hover {
          text-decoration: none;
        }
      }
      @at-root #{$self}__button_cta {
        border-width: px_to_rem(4);
        border-radius: px_to_rem(8);
        margin-bottom: px_to_rem(10);
        transition: $_transition;
        text-transform: none;
        display: inline-block;
        width: auto;
        @include font('graphik_semibold');
        @include mq($mq_md_to_lg) {
          margin-bottom: px_to_rem(20);
        }
      }
    }
    @at-root #{$self}--rounded_corners {
      border-bottom-left-radius: px_to_rem(12);
      border-bottom-right-radius: px_to_rem(12);
      overflow: hidden;
    }
  }
}
.c_wrapper_2022 {
  h2 {
    @include font('graphik_semibold');
    padding-top: px_to_rem(35);
  }
}
</style>
