<template>
  <main ref="lp_block">
    <section v-for="(component, index) in matrixBlocks.bodyContent2022" :key="index">
      <Overlay class="c_hero_banner__overlay" ref="videoBannerOverlay" theme="full" :show-close-button="true">
        <div class="c_box--pos--relative c_hero_banner__overlay__video">
          <MediaVideo loading="eager" :source="videoSource" :video-id="videoId"></MediaVideo>
        </div>
      </Overlay>
      <Header2022 v-if="component.typeHandle === 'header'" :data="component" :anchors="sectionAnchors"></Header2022>
      <ProductByTheNumbers v-if="component.typeHandle === 'productByTheNumbers'" :data="component" />
      <TextAndImage
        v-if="component.typeHandle === 'textAndImage'"
        :class="blockClasses(component)"
        :style="blockStyles(component)"
        :data="component"
      />
      <VideoPlayer
        v-if="component.typeHandle === 'videoPlayer'"
        :video-duration="component.videoDuration"
        :button-text="component.buttonText"
        :page-title="component.pageTitle"
        :image="component.image[0]"
        :image-optimized-fields="['lpBackgroundWide']"
        :background-video="component.backgroundVideo"
        :header="component.header"
        :text="component.text"
        :display-in-quick-links="component.displayInHeaderQuicklinks"
      />
      <QuoteSlider
        v-if="component.typeHandle === 'quoteSlider'"
        :slides="component.slides2"
        text-alignment="center"
        :page-title="component.pageTitle"
      />
      <PageHeader v-if="component.typeHandle === 'pageHeader'" :data="component" />
      <TextAndContentCards v-if="component.typeHandle === 'textContentCards'" :data="component" />
      <ProductOverview v-if="component.typeHandle === 'productOverviewGallery'" :data="component" />
      <VideoGallery :data="component" v-if="component.typeHandle === 'videoGallery'" />
      <RelatedResources :data="component" v-if="component.typeHandle === 'relatedResources'" />
      <RelatedProducts :data="component" v-if="component.typeHandle === 'relatedProducts'" />
      <div v-if="component.typeHandle === 'basicText'" :class="blockClasses(component)" :style="blockStyles(component)">
        <section
          class="c_wrapper c_basic_text c_2022"
          :class="{
            c_grid: component.columns !== '1',
            'lp__block--basicText--2_column': component.columns === '2',
            'lp__block--basicText--3_column': component.columns === '3',
            'c_grid--2': component.columns === '2',
            'c_grid--2-3': component.columns === '3',
          }"
        >
          <div v-for="(item, i) in component.textBlocks" :key="i" :class="{ c_basic_text: component.columns === '1' }">
            <RelativeAnchor :id="component.pageTitle" />
            <LPText
              :button-link-field="parseLinkField(item, 'superTable').valid ? item : null"
              :eyebrow="item.eyebrow || null"
              :eyebrow-style="component.columns !== '1' ? 'regular' : null"
              :subheader="item.subheader || null"
              :text="item.text || null"
              :text-alignment="item.textAlignment || null"
              :primary-cta-btn="item.primaryCtaBtn || null"
              :primary-cta-btn-label="item.primaryCtaBtnLabel || null"
              :primary-cta-btn-aria-label="item.primaryCtaBtnAriaLabel || null"
              :primary-cta-btn-theme="item.primaryCtaBtnTheme || null"
              :primary-cta-btn-open-in-a-new-window="item.primaryCtaBtnOpenInANewWindow || null"
            />
          </div>
        </section>
      </div>
      <Chart :data="component" v-if="component.typeHandle === 'chart'" />
      <FeaturedContent :data="component" v-if="component.typeHandle === 'featuredContent'" />
    </section>
  </main>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { parseLinkField, slugify } from 'JS/global.js';
import Header2022 from 'Components/2022_product_header/Header.vue';
import ProductByTheNumbers from 'Components/2022_product_by_the_numbers/ProductByTheNumbers.vue';
import TextAndImage from 'Components/2022_text_and_image/TextAndImage.vue';
import VideoPlayer from 'Components/2022_video_player/VideoPlayer.vue';
import QuoteSlider from 'Components/2022_quote_slider/QuoteSlider.vue';
import PageHeader from 'Components/2022_page_header/PageHeader.vue';
import TextAndContentCards from 'Components/2022_text_and_content_cards/TextAndContentCards.vue';
import ProductOverview from 'Components/2022_product_overview_gallery/ProductOverview.vue';
import VideoGallery from 'Components/2022_video_gallery/VideoGallery.vue';
import RelatedResources from 'Components/2022_related_resources/RelatedResources.vue';
import RelatedProducts from 'Components/2022_related_products/RelatedProducts.vue';
import LPText from 'Components/lp_text/LPText.vue';
import RelativeAnchor from 'Components/relative_anchor/RelativeAnchor.vue';
import Chart from 'Components/2022_chart/Chart.vue';
import FeaturedContent from 'Components/2022_featured_content/FeaturedContent.vue';
import MediaVideo from 'Components/video/MediaVideo.vue';
import Overlay from 'Components/overlay/Overlay.vue';

export default defineComponent({
  name: 'MatrixBlockPageContent2022',
  head: {},
  components: {
    Header2022,
    ProductByTheNumbers,
    TextAndImage,
    VideoPlayer,
    QuoteSlider,
    PageHeader,
    TextAndContentCards,
    ProductOverview,
    VideoGallery,
    RelatedResources,
    RelatedProducts,
    LPText,
    RelativeAnchor,
    Chart,
    FeaturedContent,
    MediaVideo,
    Overlay,
  },
  props: {
    matrixBlocks: Object,
  },
  setup() {},
  methods: {
    blockClasses(block) {
      const classes = [`lp__block--${block.typeHandle}`];
      // Add classes based on block type and style fields
      if (block.colors) {
        switch (block.colors) {
          case 'blackTextGrayBackground':
            classes.push('c_box--gray_235');
            break;
          case 'blackTextYellowBackground':
            classes.push('c_box--yellow');
            break;
          case 'whiteTextBlackBackground':
            classes.push('c_box--black');
            classes.push('c_text--white');
            break;
          case 'whiteTextDarkGrayBackground':
            classes.push('c_box--gray_41');
            classes.push('c_text--white');
            break;
          default:
            classes.push('c_box--white');
        }
      } else {
        classes.push('c_box--white');
      }
      return classes;
    },
    blockStyles(block) {
      const styles = {};

      // Add syles based on block type and style fields
      if (block.colors) {
        switch (block.colors) {
          case 'blackTextGrayBackground':
            styles['--block-bg-color'] = 'var(--color-gray_235)';
            break;
          case 'blackTextYellowBackground':
            styles['--block-bg-color'] = 'var(--color-yellow)';
            break;
          case 'whiteTextBlackBackground':
            styles['--block-bg-color'] = 'var(--color-black)';
            break;
          case 'whiteTextDarkGrayBackground':
            styles['--block-bg-color'] = 'var(--color-gray_41)';
            break;
          default:
            styles['--block-bg-color'] = 'var(--color-white)';
        }
      } else {
        styles['--block-bg-color'] = 'var(--color-white)';
      }
      return styles;
    },
    parseLinkField,
    formatGallerySlides(block) {
      const slides = [];
      let sources = [];
      const images = block.galleryImages;
      // log('images', block.galleryImages);
      for (let i = 0; i < images.length; i++) {
        if (images[i].image && images[i].image.length) {
          const slide = {
            header: images[i].header,
            text: images[i].text,
            button: parseLinkField(images[i], 'superTable').valid ? images[i] : null,
            videoId: images[i].image[0].videoId,
            videoSource: images[i].image[0].videoSource,
            imageTitle: images[i].image[0].title,
          };
          // log('slide initial', slide);
          if (block.overlayContent) {
            // 31.25rem = 500px, matches media query within gallery component.
            sources = [
              {
                media: '(min-width: 31.25rem)',
                srcset: images[i].image[0].lpImageGridLandscape.srcsetWebp,
                type: 'image/webp',
              },
              { media: '(min-width: 31.25rem)', srcset: images[i].image[0].lpImageGridLandscape.srcset },
              { srcset: images[i].image[0].lpGalleryPortrait.srcsetWebp, type: 'image/webp' },
              { srcset: images[i].image[0].lpGalleryPortrait.srcset },
            ];
          } else {
            sources = [
              {
                media: '(min-width: 28.125rem)',
                srcset: images[i].image[0].lpImageGridLandscape.srcsetWebp,
                type: 'image/webp',
              },
              { media: '(min-width: 28.125rem)', srcset: images[i].image[0].lpImageGridLandscape.srcset },
              { srcset: images[i].image[0].lpImageGridSquare.srcsetWebp, type: 'image/webp' },
              { srcset: images[i].image[0].lpImageGridSquare.srcset },
            ];
          }

          slide.sources = sources;
          slides.push(slide);
          // log('slide', slide);
        }
      }
      // log('slides', slides);
      return slides;
    },
    toggleVideo(videoId, videoSource) {
      this.videoId = videoId;
      this.videoSource = videoSource;
      this.$nextTick(() => {
        this.$refs.videoBannerOverlay[0].show();
      });
      event.preventDefault();
    },
  },
  data() {
    return {
      sectionAnchors: [],
      videoId: '',
      videoSource: '',
    };
  },
  created() {
    this.matrixBlocks.bodyContent2022.forEach((block) => {
      if (block.pageTitle && block.displayInHeaderQuicklinks) {
        this.sectionAnchors.push({
          title: block.pageTitle,
          anchor: '#' + slugify(block.pageTitle),
        });
      }
    });
  },
  mounted() {
    const component = this;
    this.$refs?.lp_block?.addEventListener('click', function (event) {
      if (!event.srcElement?.href) return;
      const url = event.srcElement?.href;
      // eslint-disable-next-line
      const regex =
        /^.*(?:(?:youtu\.be\/|youtube\.com\/(?:embed|v(?:i)?|e)\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
      const n = url.match(regex);
      if (n) {
        component.toggleVideo(n[1], 'youtube');
      }
      if (url.includes('youku')) {
        const id = url.split('id_')[1].slice(0, 13);
        component.toggleVideo(id, 'youku');
      }
    });
  },
});
</script>

<style lang="scss">
.lp-block {
  @at-root .c_hero_banner__overlay {
    @at-root .c_hero_banner__overlay__video {
      width: 90vw;
      max-width: 100%;
      overflow: hidden;
      @include mq($mq_sm_to_md) {
        width: 70vw;
      }
      @include mq($mq_md_to_lg) {
        width: px_to_rem(800);
      }
    }
  }
}

.c_basic_text.c_2022 {
  padding-top: px_to_rem(45);
  padding-bottom: px_to_rem(45);
  grid-gap: px_to_rem(55) var(--grid-gap);
  @include font('graphik_regular');
  @include mq($mq_sm_to_md) {
    padding-top: px_to_rem(75);
    padding-bottom: px_to_rem(75);
    grid-gap: px_to_rem(75) var(--grid-gap);
  }
  h2 {
    @include fs_lh(32, 40, 24, 30);
    @include font('graphik_semibold');
    margin-bottom: px_to_rem(19);
    @include mq($mq_sm_to_md) {
      margin-bottom: px_to_rem(23);
    }
  }
  p,
  strong,
  li,
  em {
    @include fs_lh(18, 22.5, 16, 20);
  }
  .c_lp_text__eyebrow {
    @include fs_lh(16, 21, 14, 18);
    @include font('graphik_medium');
    opacity: 1;
    margin-bottom: px_to_rem(5);
  }
  .c_lp_text {
    max-width: 100%;
  }
}
</style>
