<template>
  <section class="c_wrapper c_related_resources">
    <RelativeAnchor :id="data.pageTitle" />
    <Overlay
      class="c_2022_product__overview c_video_banner_2022__overlay"
      ref="resourceVideoOverlay"
      theme="full"
      :show-close-button="true"
    >
      <MediaVideo loading="eager" :source="'youtube'" :video-id="vidId"></MediaVideo>
    </Overlay>
    <div class="c_related_resources__title">
      <h2 class="c_2022--body-sm">{{ data.pageTitle }}</h2>
    </div>
    <Accordion class="c_related_resources__mobile_filters" :first-tab-open="false" v-if="data.displayFilter">
      <AccordionTab show-arrows mobile>
        <div slot="header">
          {{ $t('Filters') }}
        </div>
        <div slot="content">
          <RelatedResourceFilter
            :types="resourceTypes"
            :active-type="activeResourceType"
            @resourcesFiltered="filterResources"
          />
        </div>
      </AccordionTab>
    </Accordion>
    <RelatedResourceFilter
      class="c_related_resources__desktop_filters"
      :types="resourceTypes"
      :active-type="activeResourceType"
      @resourcesFiltered="filterResources"
      v-if="data.displayFilter"
    />
    <div class="c_related_resource_container--noslider" v-show="!showSlider">
      <Resource
        @overlay-triggered="overlayTriggered"
        v-for="(resource, i) in filteredResources"
        :key="i"
        :resource-obj="resource"
      />
    </div>
    <client-only v-if="showSlider">
      <div class="c_agile_slider_2022">
        <agile
          ref="relatedResourceSlider"
          @after-change="getCurrentSlide($event)"
          :options="agileOptions"
          :key="getFilteredResources.length"
        >
          <Resource
            @overlay-triggered="overlayTriggered"
            class="slide"
            v-for="(resource, i) in getFilteredResources"
            :key="i"
            :resource-obj="resource"
          />
        </agile>
        <SlideNumbers :number-of-slides="getNumberOfSlides" :current-slide="currentSlide" />
      </div>
    </client-only>
  </section>
</template>

<script>
import { defineComponent, reactive, toRefs, ref } from '@nuxtjs/composition-api';
import { VueAgile } from 'vue-agile';
import Resource from 'Components/2022_related_resources/Resource.vue';
import RelatedResourceFilter from 'Components/2022_related_resources/RelatedResourceFilter.vue';
import SlideNumbers from 'Components/slider/SlideNumbers.vue';
import Overlay from 'Components/overlay/Overlay.vue';
import MediaVideo from 'Components/video/MediaVideo.vue';

export default defineComponent({
  name: 'RelatedResources',
  components: {
    Resource,
    SlideNumbers,
    RelatedResourceFilter,
    agile: VueAgile,
    Overlay,
    MediaVideo,
    RelativeAnchor: () => import('Components/relative_anchor/RelativeAnchor.vue'),
    Accordion: () => import('Components/accordion/Accordion.vue'),
    AccordionTab: () => import('Components/accordion/AccordionTab.vue'),
  },
  props: {
    data: Object,
  },
  setup(props) {
    const state = reactive({
      resources: [],
      filteredResources: [],
      resourceLength: 0,
      resourceTypes: [{ title: 'All Resources', slug: 'all' }],
      activeResourceType: 0,
      mqSmall: 850,
      mqMedium: 1300,
      mq: null,
      currentSlide: null,
      vidId: null,
      agileOptions: {
        infinite: true,
        slidesToShow: 3,
        navButtons: true,
        dots: false,
      },
    });

    state.resourceLength = props.data.relatedResource.length;

    // Make resource object more readable
    props.data.relatedResource?.forEach((resource) => {
      state.resources.push({
        category: {
          title: resource.relatedResourceCategory[0]?.title,
          slug: resource.relatedResourceCategory[0]?.slug,
        },
        url: resource.url,
        btnOutline: resource.relatedResourceButtonOutline,
        btnTextColor: resource.relatedResourcesButtonTextColor,
        cardStyle: resource.relatedResourceCardStyling,
        description: resource.relatedResourceDescription,
        buttonLabel: resource.relatedResourceButtonLabel,
        language: resource.relatedResourceLanguage,
        title: resource.relatedResourceTitle,
        subtitle: resource.relatedResourceSubtitle,
        fileName: resource.title,
        linkOverride: resource.relatedResourceLinkOverride,
        videoId: resource.relatedResourceVideoId,
        image: {
          src: resource.relatedResourceImage[0]?.lpGalleryPortrait.src,
        },
      });
      // Add resource type to array
      if (resource.relatedResourceCategory.length) {
        state.resourceTypes.push({
          title: resource.relatedResourceCategory[0]?.title,
          slug: resource.relatedResourceCategory[0]?.slug,
        });
      }
    });

    // Remove resource type duplicates
    state.resourceTypes = state.resourceTypes.filter(
      (value, index, arr) =>
        arr.findIndex((valueAlias) =>
          ['title', 'slug'].every((indexAlias) => valueAlias[indexAlias] === value[indexAlias])
        ) === index
    );

    state.filteredResources = state.resources;

    const filterResources = (slug, i) => {
      state.activeResourceType = i;
      if (slug !== 'all') {
        state.filteredResources = state.resources.filter((resource) => resource.category.slug === slug);
      } else {
        state.filteredResources = state.resources;
      }
      state.resourceLength = state.filteredResources.length;

      return state.filteredResources;
    };

    const resourceVideoOverlay = ref(null);
    const overlayTriggered = (videoId) => {
      state.vidId = videoId;
      resourceVideoOverlay.value.show();
    };

    const getWindowDimensions = () => {
      if (typeof window !== 'undefined') {
        state.mq = document?.documentElement.clientWidth;
        if (state.mq < state.mqSmall) {
          state.agileOptions.slidesToShow = 1;
        } else if (state.mq < state.mqMedium && state.mq > state.mqSmall) {
          state.agileOptions.slidesToShow = 2;
        } else {
          state.agileOptions.slidesToShow = 3;
        }
      }
    };

    const getCurrentSlide = (e) => {
      return (state.currentSlide = e.currentSlide + 1);
    };

    return {
      ...toRefs(state),
      filterResources,
      getWindowDimensions,
      getCurrentSlide,
      overlayTriggered,
      resourceVideoOverlay,
    };
  },
  computed: {
    showSlider() {
      if (
        (this.filteredResources.length > 1 && this.mq < this.mqSmall) ||
        this.filteredResources.length > 3 ||
        (this.filteredResources.length >= 3 && this.mq < this.mqMedium)
      ) {
        return true;
      } else {
        return false;
      }
    },
    getNumberOfSlides() {
      return this.filteredResources.length;
    },
    getFilteredResources() {
      return this.filteredResources;
    },
  },
  mounted() {
    window.addEventListener('resize', this.getWindowDimensions);
  },
  unmounted() {
    window.removeEventListener('resize', this.getWindowDimensions);
  },
  created() {
    this.getWindowDimensions();
  },
});
</script>

<style lang="scss">
.c_related_resources {
  $self: &;
  padding: px_to_rem(40);
  @include mq($mq_sm_to_md) {
    padding: px_to_rem(100);
  }
  @at-root #{&}__desktop_filters {
    display: none;
    @include mq($mq_sm_to_md) {
      display: block;
    }
  }
  @at-root #{&}__mobile_filters {
    display: block;
    @include mq($mq_sm_to_md) {
      display: none;
    }
  }
  .c_accordion_tab {
    $accordion: &;
    @at-root #{&}__header {
      color: $color_gray_86;
      @include font('graphik_semibold');
      text-transform: uppercase;
      @at-root #{&}--flex {
        justify-content: flex-start;
      }
      @at-root #{&}__arrow {
        padding-left: px_to_rem(10);
        display: flex;
        align-items: center;
        svg {
          width: px_to_rem(20) !important;
          height: px_to_rem(9) !important;
        }
      }
    }
    @at-root #{&}--mobile {
      background-color: $color_white;
    }
  }
  .c_video video,
  .c_video iframe {
    height: 85%;
    width: 85%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    @include mq($mq_md_to_lg) {
      height: 100%;
      width: 100%;
    }
  }
}
.c_related_resources__title {
  display: flex;
  justify-content: center;
  margin-bottom: px_to_rem(25);
  @include mq($mq_sm_to_md) {
    margin-bottom: px_to_rem(50);
  }
  h2 {
    padding: px_to_rem(20) px_to_rem(50);
    background-color: $color_gray_240;
    @include font('graphik_medium');
  }
}
</style>
