<template>
  <div class="c_featured_content_container" :class="blockClasses(data)">
    <RelativeAnchor :id="data.pageTitle" />
    <div class="c_wrapper">
      <h2 class="c_2022--h2_graphik" v-if="data.pageTitle">{{ data.pageTitle }}</h2>
    </div>
    <div class="c_wrapper c_grid lp__block--basicText--3_column c_grid--2-3">
      <FeaturedContentCard
        v-for="(card, index) in data.featuredContent"
        :display-article-summary="data.displayArticleSummary"
        :key="index"
        :content="card"
        :class="{ 'c_card--box_shadow': showBoxShadow }"
      />
    </div>
  </div>
</template>

<script>
import RelativeAnchor from 'Components/relative_anchor/RelativeAnchor.vue';
import FeaturedContentCard from './FeaturedContentCard.vue';

export default {
  components: {
    RelativeAnchor,
    FeaturedContentCard,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      showBoxShadow: false,
    };
  },
  methods: {
    blockClasses(block) {
      const classes = [`lp__block--${block.typeHandle}`];
      // Add classes based on block type and style fields
      if (block.backgroundColor) {
        switch (block.backgroundColor) {
          case 'gray':
            classes.push('c_box--gray_230 c_text--black');
            break;
          case 'yellow':
            this.showBoxShadow = true;
            classes.push('c_box--yellow c_text--black');
            break;
          case 'black':
            classes.push('c_box--black c_text--white');
            break;
          case 'white':
            this.showBoxShadow = true;
            classes.push('c_box--white c_text--black');
            break;
          default:
            classes.push('c_box--white c_text--black');
        }
      } else {
        classes.push('c_box--white');
      }
      return classes;
    },
  },
};
</script>

<style lang="scss">
.c_featured_content_container {
  padding-top: px_to_rem(35);
  padding-bottom: px_to_rem(35);
  @include mq($mq_sm_to_md) {
    padding-top: px_to_rem(80);
    padding-bottom: px_to_rem(80);
  }
  .c_card {
    $card: &;
    @at-root #{$card}--box_shadow {
      box-shadow: 0 px_to_rem(3) px_to_rem(6) rgba(0, 0, 0, 0.15);
    }
  }
  h2 {
    @include font('graphik_semibold');
    margin-bottom: px_to_rem(20);
    @include mq($mq_sm_to_md) {
      margin-bottom: px_to_rem(40);
    }
  }
}
</style>
