<template>
  <Button class="c_asset_button" :class="[`c_asset_button--${theme}`]" v-bind="modifiedButtonOptions">
    <Grid :class="{ 'c_button--bold': theme === 'red' }" template-columns="30px 1fr" gap="11px">
      <IconSVG class="c_asset_button__icon" v-bind="modifiedIconOptions"></IconSVG>
      <span
        ><slot>{{ labelText }}</slot></span
      >
    </Grid>
  </Button>
</template>

<script>
import Button from 'Components/button/Button.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';
import Grid from 'Components/grid/Grid.vue';

export default {
  components: {
    Button,
    Grid,
    IconSVG,
  },
  data() {
    return {};
  },
  props: {
    buttonOptions: {
      type: Object,
      default() {
        return {
          reset: true,
          unstyle: true,
        };
      },
    },
    href: { type: String, required: true },
    iconOptions: {
      type: Object,
      default() {
        return {
          handle: 'file_download',
        };
      },
    },
    labelText: String,
    theme: { type: String, default: 'black' },
  },
  computed: {
    modifiedButtonOptions() {
      const returnedButtonOptions = this.buttonOptions;
      returnedButtonOptions.href = this.href;
      return returnedButtonOptions;
    },
    modifiedIconOptions() {
      const returnedIconOptions = this.iconOptions;
      return returnedIconOptions;
    },
  },
};
</script>

<style lang="scss">
.c_asset_button {
  $self: &;

  display: inline-block;
  text-decoration: none;
  font-size: var(--asset-button-font-size, 1rem);
  color: var(--button-color);

  @at-root #{$self}--black {
    --asset-button-font-size: px_to_rem(18);
    @include font('graphik_regular');
    line-height: px_to_rem(20);
    --button-color: #{$color_black};
  }

  @at-root #{$self}--red {
    --button-color: #{$color_red};
    &:hover,
    &:focus {
      --button-color: #{$color_red_179};
    }
    .c_box--gray_247 {
      --button-color: #{$color_red_179};
      &:hover,
      &:focus {
        --button-color: #{$color_red};
      }
    }
  }

  &:hover,
  &:focus {
    color: var(--button-color);
    text-decoration: underline;
  }

  @at-root #{$self}__icon {
    display: inline-block;
    width: auto;
    max-width: 100%;
    vertical-align: top; // aligns text to the top of the icon
    height: 17px;
    @include mq($mq_sm_to_md, '', 'rem') {
      height: 23px;
    }
    svg {
      width: auto;
      overflow: visible;
    }
  }
}
</style>
