<template>
  <select
    class="c_select_control"
    :class="[`c_select_control--${theme}`]"
    :style="selectStyles"
    @change="onSelectOptionChange"
    v-model="selectedValue"
  >
    <!--    <option v-if="selectOptions" v-for="item in selectOptions" :key="item.value" :value="item.value">{{-->
    <!--      item.label-->
    <!--    }}</option>-->
    <option v-if="selectOptions" v-for="item in selectOptions" :key="item.value" :value="item.value">{{
      item.label
    }}</option>
  </select>
</template>

<script>
import { background } from 'JS/automated/svg.js';

export default {
  data() {
    return {
      selectedValue: 'default',
    };
  },
  props: {
    selectOptions: Array,
    theme: { type: String, default: 'standalone' },
  },
  computed: {
    selectStyles() {
      const styles = {};

      if (this.theme === 'bar') {
        styles.backgroundImage = background('arrow_down', {
          '0 0 35.33 17.46': '-2 -2 39.33 21.46',
          'stroke="#000"': 'stroke="rgb(112, 112, 112)"',
          'stroke-width="3"': 'stroke-width="5"',
        });
      } else if (this.theme === 'standalone') {
        styles.backgroundImage = background('arrow_down', { 'stroke="#000"': 'stroke="rgb(112, 112, 112)"' });
      }

      return styles;
    },
  },
  methods: {
    onSelectOptionChange(event) {
      this.$emit('selectOptionChange', event.target.value);
    },
    updateSelectedValue(newValue) {
      if (this.selectOptions.some((item) => item.value === newValue).length) {
        this.selectedValue = newValue;
      }
    },
  },
};
</script>

<style lang="scss">
.c_select_control {
  $self: &;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  @at-root #{$self}--bar {
    padding: 0 2.8em 0 1.2em;
    height: 100%;
    // width: 100%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 18px 9px;
    background-position: right 0.8em top 50%, 0 0;
    border: none;
    border-right: 2px solid $color_gray_191;
    border-radius: 0;
    text-transform: uppercase;
    @include font('graphik_semibold');
    color: $color_gray_112;
  }
  @at-root #{$self}--standalone {
    padding: 0.3em 2em 0.3em 0.5em;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 13px 10px;
    background-position: right 0.7em top 50%, 0 0;
    border: 1px solid $color_gray_112;
    border-radius: $border_radius;
    @include font('graphik_light');
    color: $color_gray_112;
  }
  &:hover,
  &:focus {
    color: $color_red;
    cursor: pointer;
  }
  option {
    color: $color_black;
  }
}
</style>
