<template>
  <section class="c_video_gallery">
    <RelativeAnchor :id="data.pageTitle" />
    <div class="c_wrapper">
      <h2 class="c_2022--h2_graphik">{{ data.pageTitle }}</h2>
      <div class="c_video_gallery__container">
        <VideoGalleryFeatured :video="getActiveVideo" />
        <div class="c_video_gallery__video-playlist">
          <div
            @click="makeActiveVideo(video, i)"
            class="c_video_gallery__video-item"
            v-for="(video, i) in videoSources"
            :key="i"
            :class="{ 'c_video_gallery__video-item--active': i === activeVideo }"
          >
            <div class="c_video_gallery__thumbnail-container">
              <MediaImage
                class="c_video_gallery__thumbnail"
                :sources="video.sourcesThumbnails"
                :alt="video.description"
              />
              <span v-if="video.duration" class="c_video_gallery__duration c_2022--caption">{{ video.duration }}</span>
            </div>
            <div class="c_video_gallery__thumbnail-text-container">
              <span class="c_video_gallery__video-type c_2022--body" v-if="video.type">{{ video.type }}</span>
              <span class="c_video_gallery__video-description c_2022--caption" v-if="video.description">
                {{ video.description }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {
    MediaImage: () => import('Components/image/MediaImage.vue'),
    VideoGalleryFeatured: () => import('Components/2022_video_gallery/VideoGalleryFeatured.vue'),
    RelativeAnchor: () => import('Components/relative_anchor/RelativeAnchor.vue'),
  },
  props: {
    data: Object,
  },
  data() {
    return {
      featuredVideo: null,
      // Set active video to first in array
      activeVideo: 0,
      videoSources: [],
    };
  },
  methods: {
    makeActiveVideo(vid, i) {
      this.featuredVideo = vid;
      this.activeVideo = i;
    },
  },
  computed: {
    getActiveVideo() {
      return this.featuredVideo;
    },
  },
  created() {
    // Create more readable object for video sources
    this.data.videos?.forEach((video) => {
      this.videoSources.push({
        type: video.videoType,
        description: video.videoDescription,
        id: video.videoId,
        source: video.videoSource,
        duration: video.videoDuration,
        sourcesMain: [
          { srcset: video.galleryEnlarged.srcWebp, type: 'image/webp' },
          { srcset: video.galleryEnlarged.srcset },
        ],
        sourcesThumbnails: [
          { srcset: video.galleryThumbnail.srcWebp, type: 'image/webp' },
          { srcset: video.galleryThumbnail.srcset },
        ],
      });
    });
    // Make default featured video the first in the Craft array
    this.featuredVideo = this.videoSources[0];
  },
};
</script>

<style lang="scss">
$gallery_max_height: px_to_rem(540);

.c_video_gallery {
  $self: &;
  padding: px_to_rem(30) 0;
  @include mq($mq_sm_to_md) {
    padding: px_to_rem(50) 0;
  }
  h2 {
    margin-bottom: px_to_rem(10);
    padding-left: px_to_rem(25);
    @include font('graphik_semibold');
    @include mq($mq_sm_to_md) {
      padding-left: 0;
    }
  }
  @at-root #{&}__container {
    border-top: 3px solid $color_yellow;
    border-bottom: none;
    @include mq($mq_md_to_lg) {
      display: flex;
      border-bottom: 3px solid $color_yellow;
    }
  }
  @at-root #{&}__video-playlist {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    border-top: 3px solid $color_yellow;
    @include mq($mq_md_to_lg) {
      max-height: $gallery_max_height;
      width: px_to_rem(560);
      overflow-y: auto;
      border-top: none;
    }
  }
  @at-root #{&}__video-item {
    padding: px_to_rem(18) px_to_rem(25);
    display: flex;
    align-items: flex-start;
    background-color: $color_white;
    transition: background-color ease-in-out 0.15s;
    cursor: pointer;
    &:hover {
      background-color: $color_gray_230;
    }
    @at-root #{$self}__video-type {
      display: block;
      color: $color_black;
      @include font('graphik_regular');
    }
    @at-root #{$self}__thumbnail-text-container {
      padding-left: px_to_rem(15);
    }
    @at-root #{$self}__video-description {
      display: block;
    }
    @at-root #{$self}__video-item--active {
      background-color: $color_gray_230;
      // Child el class ends with:
      [class$='__thumbnail-container'] {
        border-bottom: px_to_rem(3) solid $color_red_255;
      }
    }
  }
  @at-root #{&}__thumbnail-container {
    flex-shrink: 0;
    position: relative;
    border-bottom: px_to_rem(3) solid transparent;
    width: px_to_rem(180);
    @include mq($mq_lg) {
      width: px_to_rem(208);
    }

    @at-root #{$self}__thumbnail {
      picture {
        display: flex;
        max-height: px_to_rem(146);
        overflow: hidden;
        img {
          object-fit: cover;
        }
      }
    }
    @at-root #{$self}__duration {
      position: absolute;
      @include font('graphik_regular');
      bottom: px_to_rem(10);
      right: px_to_rem(10);
      z-index: 5;
      background-color: $color_black;
      border-radius: px_to_rem(2);
      padding: px_to_rem(2) px_to_rem(3);
      color: $color_white;
    }
  }
  .c_wrapper {
    @media (max-width: $mq_sm_to_md + 'px') {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
