var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"c_text_and_image",staticClass:"c_text_and_image",class:[_vm.getContainerClasses, _vm.data.layoutStyle],style:([
    _vm.isBackgroundImage && _vm.data.image[0]
      ? {
          backgroundImage: _vm.getBackgroundImage,
          backgroundPosition: _vm.getBackgroundPosition,
        }
      : '',
  ])},[_c('RelativeAnchor',{attrs:{"id":_vm.data.pageTitle}}),_vm._v(" "),_c('div',{staticClass:"c_wrapper"},[_c('div',{class:_vm.layoutStyle,style:({ marginLeft: _vm.getTextPosition })},[(_vm.data.image[0]?.lpBackgroundWide && _vm.largeImg)?_c('mq-layout',{staticClass:"c_text_and_image__large_img",style:({
          backgroundImage: 'url(' + _vm.data.image[0].lpBackgroundWide.src + ')',
          backgroundPosition: _vm.getBackgroundPosition,
        }),attrs:{"mq":"md+"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"c_wrapper__background_color",class:{ 'c_text--black': _vm.forceBlackText, 'c_text--white': _vm.forceWhiteText }},[_c('div',{class:_vm.layoutStyle + '__image'},[(_vm.data.image[0]?.lpImageTextUncropped)?_c('MediaImage',{attrs:{"sources":_vm.formatSources(_vm.data.image[0]),"alt":_vm.data.image[0].title}}):_vm._e(),_vm._v(" "),(!_vm.data.image[0]?.lpImageTextUncropped)?_c('img',{staticClass:"c_image",attrs:{"src":_vm.data.image[0]?.url,"alt":_vm.data.image[0]?.title}}):_vm._e(),_vm._v(" "),(_vm.data.label && _vm.data.layoutStyle === 'iconLeftTextRight')?_c('h2',{staticClass:"c_2022--h2_graphik"},[_vm._v("\n            "+_vm._s(_vm.data.label)+"\n          ")]):_vm._e()],1),_vm._v(" "),_c('div',{class:_vm.layoutStyle + '__text_container'},[(_vm.data.label && _vm.data.layoutStyle !== 'iconLeftTextRight')?_c('h2',{staticClass:"c_2022--h2_graphik"},[_vm._v("\n            "+_vm._s(_vm.data.label)+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.data.description)?_c('div',{staticClass:"c_2022--body",domProps:{"innerHTML":_vm._s(_vm.data.description)}}):_vm._e(),_vm._v(" "),(_vm.data.ctaButton)?_c('div',{staticClass:"c_text_and_image__button_container"},_vm._l((_vm.data.ctaButton),function(button,index){return _c('a',{key:index,tag:"Button",staticClass:"c_button_2022",class:_vm.buttonClasses(button),attrs:{"unstyle":"","reset":"","aria-label":button.ariaLabel,"href":_vm.getHrefs(button),"target":_vm.getTarget(button)}},[_vm._v("\n              "+_vm._s(button.buttonLabel)+"\n            ")])}),1):_vm._e()])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }