<template>
  <div
    class="c_tabs"
    :class="[
      theme ? 'c_tabs--' + theme : '',
      !showTabBorder ? 'c_tabs--no_border' : '',
      backgroundColor && $mq !== 'sm' ? 'c_box--' + backgroundColor : '',
      mobileBackgroundColor && $mq === 'sm' ? 'c_box--' + mobileBackgroundColor : '',
      horizontalScrollOnWrapper ? 'c_tabs--horizontal_scroll' : '',
    ]"
  >
    <Flex
      class="c_tabs__tabs c_wrapper"
      :class="[
        tabs.length <= 4 ? 'c_tabs__tabs--partial' : '',
        !horizontalScrollOnWrapper ? 'c_tabs__tabs--horizontal_scroll' : '',
      ]"
    >
      <Button
        class="c_tabs__tabs__tab c_header--uppercase"
        :class="[
          (identifier === 'index' ? tabIndex : tab[identifier]) === activeIdentifier
            ? ['c_text--graphik_semibold', 'c_tabs__tabs__tab--active']
            : '',
          theme !== 'fullWidth' ? 'c_header--3' : '',
        ]"
        v-for="(tab, tabIndex) in tabs"
        :key="'c_tabs__tabs--' + tabIndex"
        reset
        unstyle
        @onClick="clickThroughAction(tab, tabIndex)"
      >
        <span class="c_tabs__tabs__tab__span" v-html="tab.tabTitle"> </span>
      </Button>
      <div class="c_tabs__tabs__border"></div>
    </Flex>
  </div>
</template>

<script>
import { log } from 'JS/global.js';
import Button from 'Components/button/Button.vue';
import Flex from 'Components/flex/Flex.vue';
export default {
  components: {
    Button,
    Flex,
  },
  data() {
    return {};
  },
  props: {
    activeIdentifier: null,
    backgroundColor: {
      type: String,
      default: null,
    },
    horizontalScrollOnWrapper: {
      type: Boolean,
      default: false,
    },
    identifier: null,
    mobileBackgroundColor: {
      type: String,
      default: null,
    },
    tabs: Array,
    theme: {
      type: String,
      default: null,
    },
    showTabBorder: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  methods: {
    clickThroughAction(tab, tabIndex) {
      log('Click Through Action', tab, this.identifier === 'index' ? tabIndex : this.identifier);
      this.$emit('onClickTab', tab, this.identifier === 'index' ? tabIndex : this.identifier);
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss">
.c_tabs {
  $self: &;
  --tabs-mobile-background-color: transparent;
  --tabs-border-color: #{$color_gray_235};
  --tabs-tab-border-color: #{$color_yellow};
  --tabs-tab-hover-color: #{$color_red};
  position: relative;
  overflow: hidden;

  @at-root .c_box--gray_235 #{&} {
    --tabs-border-color: #{$color_white};
  }
  @at-root .c_box--yellow #{&} {
    --tabs-border-color: #{$color_black};
    --tabs-tab-border-color: #{$color_red};
  }

  @at-root .c_box--white #{&} {
    --tabs-border-color: #{$color_gray_191};
  }

  @at-root #{$self}:not(#{$self}--fullWidth) {
    margin-bottom: px_to_rem(20);
    @include mq($mq_gallery_video, '', 'rem') {
      margin-bottom: px_to_rem(50);
    }
  }

  @at-root #{&}--horizontal_scroll {
    @include horizontal_scroll(false);
  }

  @at-root #{$self}__tabs {
    $tabs: &;
    width: 100%;
    justify-content: space-between;
    @include mq_max($mq_sm_to_md) {
      background-color: var(--tabs-mobile-background-color);
    }
    @include mq_max(1150, '', 'rem') {
      padding: 0;
    }

    @at-root #{$self}:not(#{$self}--fullWidth) & {
      padding: 0;
    }
    @include mq($mq_gallery_video, '', 'rem') {
      @at-root #{$self}:not(#{$self}--fullWidth) & {
        margin-left: 0;
      }
    }

    @at-root #{&}--horizontal_scroll {
      @include horizontal_scroll(false);
    }

    @at-root #{&}--partial {
      justify-content: flex-start;
      @at-root #{&} #{$tabs}__tab {
        margin-right: px_to_rem(50);
      }
    }
    @at-root #{&}__tab {
      $tab: &;
      position: relative;
      z-index: 50; // set tab above tab__border element
      font-size: 1.27rem;
      padding: 0 4vw;
      flex: 0 0 auto;
      @at-root #{$self}:not(#{$self}--fullWidth) & {
        padding: 0;
      }
      @include mq(1150, '', 'rem') {
        flex: 0 1 auto;
        padding: 0;
      }
      @at-root #{&}__span {
        display: inline-block;
        padding-bottom: px_to_rem(15);
        border-bottom: px_to_rem(5) solid transparent;
        #{$tab}:hover &,
        #{$tab}:focus & {
          border-bottom-color: #{$color_black};
          .c_box--black & {
            border-bottom-color: #{$color_white};
          }
        }
        @at-root #{$self}--fullWidth #{&} {
          padding: px_to_rem(15) 0;
        }
        @at-root #{$tab}--active #{&} {
          border-bottom-color: var(--tabs-tab-border-color);
        }
      }
      @at-root #{&}--last {
        padding-right: var(--wrapper-padding, 4vw);
      }
    }
    @at-root #{&}__border {
      position: absolute;
      bottom: px_to_rem(2);
      left: 0;
      right: 0;
      width: 100%;
      height: px_to_rem(1);
      background: var(--tabs-border-color);

      @at-root #{$self}--no_border #{&} {
        display: none;
      }
    }
  }
}
</style>
