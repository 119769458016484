var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_tabs",class:[
    _vm.theme ? 'c_tabs--' + _vm.theme : '',
    !_vm.showTabBorder ? 'c_tabs--no_border' : '',
    _vm.backgroundColor && _vm.$mq !== 'sm' ? 'c_box--' + _vm.backgroundColor : '',
    _vm.mobileBackgroundColor && _vm.$mq === 'sm' ? 'c_box--' + _vm.mobileBackgroundColor : '',
    _vm.horizontalScrollOnWrapper ? 'c_tabs--horizontal_scroll' : '',
  ]},[_c('Flex',{staticClass:"c_tabs__tabs c_wrapper",class:[
      _vm.tabs.length <= 4 ? 'c_tabs__tabs--partial' : '',
      !_vm.horizontalScrollOnWrapper ? 'c_tabs__tabs--horizontal_scroll' : '',
    ]},[_vm._l((_vm.tabs),function(tab,tabIndex){return _c('Button',{key:'c_tabs__tabs--' + tabIndex,staticClass:"c_tabs__tabs__tab c_header--uppercase",class:[
        (_vm.identifier === 'index' ? tabIndex : tab[_vm.identifier]) === _vm.activeIdentifier
          ? ['c_text--graphik_semibold', 'c_tabs__tabs__tab--active']
          : '',
        _vm.theme !== 'fullWidth' ? 'c_header--3' : '',
      ],attrs:{"reset":"","unstyle":""},on:{"onClick":function($event){return _vm.clickThroughAction(tab, tabIndex)}}},[_c('span',{staticClass:"c_tabs__tabs__tab__span",domProps:{"innerHTML":_vm._s(tab.tabTitle)}})])}),_vm._v(" "),_c('div',{staticClass:"c_tabs__tabs__border"})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }