import { render, staticRenderFns } from "./GalleryVideo.vue?vue&type=template&id=8e4be402"
import script from "./GalleryVideo.vue?vue&type=script&lang=js"
export * from "./GalleryVideo.vue?vue&type=script&lang=js"
import style0 from "./GalleryVideo.vue?vue&type=style&index=0&id=8e4be402&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports