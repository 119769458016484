<template>
  <div class="product">
    <div class="page-wrapper" v-if="entry && entry.typeHandle === 'productPage'">
      <Breadcrumb class="c_box--gray_235" :links="breadcrumbLinks" />
      <header>
        <div class="product__overview c_wrapper c_box--margin" v-if="Object.keys(entry).length">
          <div class="product__overview__grid">
            <Flex direction="column" justify="space-between" class="product__overview__info">
              <div>
                <h1 class="c_header c_header--1" v-if="entry.title" v-html="entry.title"></h1>
                <p class="product__overview__subhead respect-breaks" v-if="entry.productOverviewSubhead">
                  {{ entry.productOverviewSubhead }}
                </p>
                <mq-layout class="c_box--margin--top c_box--break_out" :mq="['sm', 'md']">
                  <Slider
                    show-indicators
                    slider-type="productMain"
                    :on-overlay="false"
                    :arrows="$mq !== 'lg' ? 'overlay' : 'inside'"
                    :main-height="$mq !== 'lg' ? '350px' : '500px'"
                    :slide-image-sources="galleryImageSources"
                    @mainItemClicked="onOverviewGalleryMainItemClick"
                  />
                </mq-layout>
                <div class="product__overview__description respect-breaks" v-if="entry.productOverviewDescription">
                  <p>{{ entry.productOverviewDescription }}</p>
                </div>
              </div>
              <Flex
                class="product__overview__resources"
                align="flex-start"
                justify="space-between"
                v-if="entry.productBrochure.length || moreResourcesTab"
              >
                <AssetButton theme="red" :href="entry.productBrochure[0].url" v-if="entry.productBrochure.length">{{
                  entry.productBrochure[0].title
                }}</AssetButton>
                <Button
                  class="product__overview__resources__more c_button--pointer c_button--reset"
                  @onClick="onProjectTabClick(moreResourcesTab)"
                  v-if="moreResourcesTab"
                  ><span class="c_button--bold">{{ $t('More resources') }}</span></Button
                >
              </Flex>
              <div class="product__overview__buttons c_buttons">
                <LinkField
                  :button-attrs="{ color: index > 0 ? 'gray_112' : null, outline: index > 0 }"
                  :matrix-field="item"
                  v-for="(item, index) in entry.linksMatrix"
                  :key="index"
                />
              </div>
            </Flex>
            <mq-layout class="product__overview__gallery" mq="lg+">
              <Slider
                show-indicators
                slider-type="thumbnails"
                show-thumbnails
                :main-height="'355px'"
                arrows="outside"
                :slide-image-sources="galleryImageSources"
                @mainItemClicked="onOverviewGalleryMainItemClick"
              />
            </mq-layout>
            <Overlay ref="overview_gallery_overlay" width="auto" height="auto" :show-close-button="true">
              <Slider
                class="product__overview__gallery__overlay_slider c_box--black"
                captions
                video-player
                arrows="outside"
                :on-overlay="true"
                :disable-hover-effect="true"
                :main-height="$mq === 'sm' ? '350px' : '506px'"
                :slide-image-sources="galleryImageSources"
                :starting-slide-index="overviewGalleryOverlaySliderIndex"
                ref="overview_gallery_slider"
              />
            </Overlay>
          </div>
        </div>
      </header>
      <div v-if="productTabs">
        <div>
          <Tabs
            id="details"
            class="product__tab_buttons"
            background-color="gray_235"
            mobile-background-color="white"
            identifier="slug"
            :horizontal-scroll-on-wrapper="true"
            :active-identifier="productTab ? productTab.slug : null"
            :show-tab-border="false"
            :tabs="productTabs"
            theme="fullWidth"
            @onClickTab="onProjectTabClick"
            v-if="productTab && productTabs"
          />
        </div>
        <article class="product__tab c_box--gray_247" v-if="productTab">
          <ProductTabCompare
            :tab-data="productTab"
            :key="productTab.id"
            v-if="productTab.typeHandle === 'compareTab'"
          />
          <ProductTabContent
            :tab-data="productTab"
            :key="productTab.id"
            v-if="productTab.typeHandle === 'contentTab'"
          />
          <ProductTabRelated
            :tab-data="productTab"
            :key="productTab.id"
            v-else-if="productTab.typeHandle === 'relatedTab'"
          />
          <ProductTabResources
            :tab-data="productTab"
            :key="productTab.id"
            v-else-if="productTab.typeHandle === 'resourcesTab'"
          />
          <ProductTabTable
            :tab-data="productTab"
            :key="productTab.id"
            v-else-if="productTab.typeHandle === 'tableTab'"
          />
          <ProductTabMultiColumnTable
            :tab-data="productTab"
            :key="productTab.id"
            v-else-if="productTab.typeHandle === 'multiColumnTableTab'"
          />
        </article>
      </div>

      <RelatedProducts
        element-type="aside"
        :header="$t('Related Products')"
        :related-entries="entry.relatedProducts"
        v-if="entry.relatedProducts && entry.relatedProducts.length"
      />
      <div style="display: none" v-if="entry.productDisclaimerFootnote[0]">
        {{ entry.productDisclaimerFootnote[0].content }}
      </div>
      <DisclaimerFootnote
        v-if="entry.productDisclaimerFootnote"
        :disclaimers="entry.productDisclaimerFootnote"
        class="product__product_disclaimer"
      ></DisclaimerFootnote>
    </div>
    <div class="page-wrapper" v-if="entry && entry.typeHandle === 'productPage2022'">
      <Breadcrumb class="c_box--gray_235" :links="breadcrumbLinks" />
      <MatrixBlockPageContent2022 :matrix-blocks="entry"></MatrixBlockPageContent2022>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  useContext,
  useFetch,
  useMeta,
  useRoute,
  useRouter,
  useStore,
} from '@nuxtjs/composition-api';
import { productGql } from 'GQL/pages/productGql.js';
import { log } from 'JS/global.js';
import { gqlToObject } from 'JS/seomatic.js';
import AssetButton from 'Components/asset_button/AssetButton.vue';
import Breadcrumb from 'Components/breadcrumb/Breadcrumb.vue';
import Button from 'Components/button/Button.vue';
import DisclaimerFootnote from 'Components/disclaimer_footnote/DisclaimerFootnote.vue';
import Flex from 'Components/flex/Flex.vue';
import LinkField from 'Components/link_field/LinkField.vue';
import Overlay from 'Components/overlay/Overlay.vue';
import ProductTabCompare from 'Components/product_tab/ProductTabCompare.vue';
import ProductTabContent from 'Components/product_tab/ProductTabContent.vue';
import ProductTabMultiColumnTable from 'Components/product_tab/ProductTabMultiColumnTable.vue';
import ProductTabRelated from 'Components/product_tab/ProductTabRelated.vue';
import ProductTabResources from 'Components/product_tab/ProductTabResources.vue';
import ProductTabTable from 'Components/product_tab/ProductTabTable.vue';
import RelatedProducts from 'Components/related_products/RelatedProducts.vue';
import Slider from 'Components/slider/Slider.vue';
import Tabs from 'Components/tabs/Tabs.vue';
import MatrixBlockPageContent2022 from 'Components/matrix_blocks/MatrixBlockPageContent2022';

export default defineComponent({
  name: 'PageProduct',
  head: {},
  components: {
    AssetButton,
    Breadcrumb,
    Button,
    DisclaimerFootnote,
    Flex,
    LinkField,
    Overlay,
    ProductTabCompare,
    ProductTabContent,
    ProductTabMultiColumnTable,
    ProductTabRelated,
    ProductTabResources,
    ProductTabTable,
    RelatedProducts,
    Slider,
    Tabs,
    MatrixBlockPageContent2022,
  },
  setup() {
    const { app, $craft, payload, $preview } = useContext();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      entry: null,
      galleryImageSources: [],
      overviewGalleryOverlaySliderIndex: 1,
      productTab: null,
      productTabs: null,
      productTabUri: null,
      seomatic: null,
    });

    useFetch(async () => {
      let data;
      let uri = `${route.value.params.contentSection}/product/`;
      if (route.value.params.category1) {
        uri += `${route.value.params.category1}/`;

        if (route.value.params.category2) {
          uri += `${route.value.params.category2}/`;

          if (route.value.params.category3) {
            uri += `${route.value.params.category3}/`;

            if (route.value.params.category4) {
              uri += `${route.value.params.category4}/`;

              if (route.value.params.category5) {
                uri += `${route.value.params.category5}/`;
              }
            }
          }
        }
      }
      const fallbackUri = uri.endsWith('/') ? uri.slice(0, -1) : uri;
      uri += `${route.value.params.slug}`;

      if (!$preview && (payload?.fallbackEntry || payload?.entry)) {
        data = payload;
      } else {
        const request = await $craft({
          apiLog: 'PageProduct',
          query: productGql,
          variables: {
            fallbackUri,
            site: store.state.global.localeSites[app.i18n.locale].siteHandle,
            uri,
          },
        });

        if (request?.data) {
          data = request.data;
        }
      }

      if (data?.fallbackEntry || data?.entry) {
        if (data.fallbackEntry) {
          state.entry = data.fallbackEntry;
          state.productTabUri = uri;
          // eslint-disable-next-line no-console
          // console.log('fallback entry');
        } else if (data.entry) {
          state.entry = data.entry;
          // eslint-disable-next-line no-console
          // console.log('product entry');
        }

        if (state.entry.children?.length) {
          state.productTabs = state.entry.children;

          state.productTab = computed(() => {
            if (state.productTabs?.length) {
              const tabForUri = state.productTabs.find((tab) => {
                return `/${app.i18n.locale}/${tab.uri}/` === route.value.path;
              });
              if (tabForUri) {
                return tabForUri;
              }
              return state.productTabs[0];
            }
            return null;
          });
        }

        state.entry.productOverviewGallery?.forEach((item) => {
          state.galleryImageSources.push({
            alt: item.title,
            sourcesMain: [
              { srcset: item.galleryEnlarged.srcsetWebp, type: 'image/webp' },
              { srcset: item.galleryEnlarged.srcset },
            ],
            sourcesThumbnails: [
              { srcset: item.galleryThumbnail.srcsetWebp, type: 'image/webp' },
              { srcset: item.galleryThumbnail.srcset },
            ],
            videoId: item.videoId || false,
            videoSource: item.videoSource || '',
            caption: item.caption || false,
          });
        });

        if (state.entry?.seomatic) {
          // todo Get SEO info from tab entries
          state.seomatic = state.entry.seomatic;
        }
        if (state.entry?.navigationTheme) {
          store.commit('global/updateGlobalDarkTheme', true);
        } else {
          store.commit('global/updateGlobalDarkTheme', false);
        }
      } else {
        router.replace('/error/404');
      }
    });

    useMeta(() => {
      return state.seomatic ? { ...gqlToObject(state.seomatic) } : {};
    });

    return { ...toRefs(state) };
  },
  computed: {
    breadcrumbLinks() {
      const links = [{ label: this.$t('Home'), href: '/' }];

      if (this.entry) {
        if (this.entry.productCategories) {
          this.entry.productCategories.forEach((item) => {
            links.push({
              label: item.title,
              href: item.breadcrumbLink?.length ? item.breadcrumbLink[0]?.uri : item.uri,
            });
          });
        }
        links.push({ label: this.entry.title });
      }

      return links;
    },
    moreResourcesTab() {
      if (this.entry?.children) {
        const resourceTab = this.entry.children.filter((item) => item.openOnMoreResourcesClick === true);
        if (resourceTab.length) {
          return resourceTab[0];
        }
      }

      return false;
    },
  },
  methods: {
    onOverviewGalleryMainItemClick(index) {
      this.$refs.overview_gallery_overlay.show();
      this.$nextTick(function () {
        this.$refs.overview_gallery_slider?.goTo(index);
      });
    },
    onProjectTabClick(tab) {
      this.$router.push(`/${this.$i18n.locale}/${tab?.uri}/`);
      // history.pushState({}, '', `/${this.$route.params.locale}/${tab.uri}`);
    },
    showOverlay(overlayRef) {
      if (this.$refs[overlayRef]) {
        this.$refs[overlayRef].show();
      }
    },
  },
  mounted() {
    log('PageProduct loaded');
  },
});
</script>

<style lang="scss">
.product {
  $self: &;

  @at-root #{$self}__overview {
    $overview: &;
    /* WIDGET OVERRIDE - Constrain to 16 x 9 Aspect Ratio */
    .c_slider__slide {
      display: block;
      .c_image_bg {
        @include aspect-ratio(16, 9);
        img {
          object-fit: contain;
          @include mq(1024, '', 'rem') {
            object-fit: cover;
          }
        }
      }
    }
    @at-root #{$overview}__grid {
      $__grid_gap: 57px;

      display: grid;
      grid-gap: $__grid_gap;
      grid-template-columns: 100%;

      @include mq($mq_md_to_lg) {
        grid-template-columns: calc(50% - #{$__grid_gap / 2}) 1fr;
      }
    }
    @at-root #{$overview}__info {
      margin-top: -0.4rem;

      @include mq($mq_md_to_lg) {
        grid-column: 2;
        grid-row: 1;
      }
    }
    @at-root #{$overview}__subhead {
      margin-top: 0.6rem;

      @include mq($mq_sm_to_md) {
        @include font('graphik_semibold');
        font-size: 1.2rem;
      }
    }
    @at-root #{$overview}__description {
      margin-top: 1.05rem;
      font-size: 1.25rem;
    }
    @at-root #{$overview}__resources {
      margin-top: 2.1rem;

      @at-root #{&}__more {
        margin-left: 25px;
        flex-grow: 0;
        white-space: nowrap;
        text-decoration: none;

        @at-root #{&}:first-child {
          margin-left: 0;
        }
      }
    }
    @at-root #{$overview}__buttons {
      margin-top: 2.3rem;
    }
    @at-root #{$overview}__gallery {
      position: relative;

      @include mq($mq_md_to_lg) {
        grid-column: 1;
        grid-row: 1;
      }

      @at-root #{&}__overlay_slider {
        width: 90vw;
        @include mq($mq_md_to_lg) {
          width: px_to_rem(800);
        }
        @include mq_vmax(600px) {
          --slider-main-height: 300px !important;
        }
      }
    }
  }

  @at-root #{$self}__tab_buttons {
    --tabs-mobile-background-color: $color_white;
  }

  @at-root #{$self}__tab {
    @include mq($mq_sm_to_md) {
      padding: $spacing_box 0 $spacing_box_bottom;
      min-height: 50vh;
    }
    .c_accordion_tab__header {
      &:hover,
      &:focus {
        color: $color_red;
      }
    }
  }
  @at-root #{$self}__product_disclaimer {
    background-color: #f0f0f0;
  }
}
.product_tab_loader_container {
  /* For Child Loader Components */
  position: relative;
  width: 100%;
  height: 25vh;
  display: none;
}
.product_tab_loader_container.active {
  display: flex;
}
.product_tab_wrapper.hide {
  display: none;
}
</style>
