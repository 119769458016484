<template>
  <div class="c_box--black" ref="c_products_by_the_numbers">
    <RelativeAnchor :id="data.pageTitle" />
    <div class="products_by_the_number__wrap c_wrapper_2022">
      <div class="products_by_the_number__intro">
        <h2 class="products_by_the_number__intro__title c_header--5">{{ data.pageTitle }}</h2>
        <p class="c_text">{{ data.pageDescription }}</p>
      </div>
      <ul class="products_by_the_number" :class="itemClass">
        <li class="products_by_the_number__item" v-for="(product, index) in data.productByTheNumber" :key="index">
          <div class="c_container">
            <div class="products_by_the_number__item__icon_container">
              <img v-if="product.icon.length" :src="product.icon[0].url" :alt="product.icon[0].title" />
            </div>
            <div class="products_by_the_number__item__title">
              <div class="products_by_the_number__item__label">
                {{ product.label }}
              </div>
              <div class="products_by_the_number__item__unit">
                {{ product.unit }}
              </div>
            </div>
            <div class="products_by_the_number__item__description c_2022--body" v-html="product.description"></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { openPdfsInNewTab } from 'JS/global.js';
export default {
  components: {
    RelativeAnchor: () => import('Components/relative_anchor/RelativeAnchor.vue'),
  },
  props: {
    data: Object,
  },
  data() {
    return {
      itemClass: 'products_by_the_number__' + this.data.productByTheNumber.length,
    };
  },
  mounted() {
    openPdfsInNewTab(this.$refs.c_products_by_the_numbers);
  },
};
</script>

<style lang="scss">
.products_by_the_number {
  $self: &;
  color: $color_white;
  background-color: $color_black;
  display: flex;
  justify-content: center;
  @include mq($mq_md_to_lg) {
    justify-content: flex-start;
  }
  * {
    box-sizing: border-box;
  }
  @at-root #{$self}__wrap {
    padding-top: px_to_rem(30);
    padding-bottom: px_to_rem(30);
    @include mq($mq_md_to_lg) {
      padding-top: px_to_rem(80);
      padding-bottom: px_to_rem(90);
    }
  }
  @at-root #{$self}__intro {
    color: $color_white;
    padding-bottom: px_to_rem(50);
    @at-root #{&}__title {
      font-size: px_to_rem(32);
      margin-bottom: px_to_rem(10);
    }
  }
  @at-root #{$self}__item {
    $item: &;
    border-left: px_to_rem(0.5) solid $color_gray_139;
    padding: px_to_rem(10) px_to_rem(30);
    flex: 0 0 25%;
    width: 25%;

    &:last-of-type {
      border-right: px_to_rem(0.5) solid $color_gray_139;
    }
    @at-root #{$item}__icon_container {
      margin-bottom: px_to_rem(16);
      img {
        height: px_to_rem(50);
        width: auto;
      }
    }
    @at-root #{$item}__title {
      margin-bottom: px_to_rem(10);
      @at-root #{$item}__label {
        font-size: px_to_rem(40);
        line-height: px_to_rem(40);
        margin-right: px_to_rem(10);
      }
      @at-root #{$item}__unit {
        font-size: px_to_rem(20);
        line-height: px_to_rem(24);
        color: $color_yellow;
        padding-bottom: px_to_rem(8);
      }
    }
    @at-root #{$item}__description {
      color: $color_gray_201;
    }
  }
  @media (max-width: 1050px) {
    flex-wrap: wrap;
  }
  @at-root #{$self}__3 {
    @media (max-width: $mq_md_to_lg + 'px') {
      .products_by_the_number__item {
        border: none;
        &:first-child {
          border-right: px_to_rem(0.5) solid $color_gray_139;
        }
        &:nth-child(2n) {
          border-right: none;
        }
        &:last-of-type {
          border-right: none;
          border-top: px_to_rem(0.5) solid $color_gray_139;
          flex: 0 0 100%;
          padding-top: px_to_rem(75);
        }
      }
    }
    @media (max-width: $mq_sm_to_md + 'px') {
      .products_by_the_number__item,
      .products_by_the_number__item:last-of-type,
      .products_by_the_number__item:first-of-type,
      .products_by_the_number__item:nth-child(2n) {
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: px_to_rem(0.5) solid $color_gray_139;
      }
    }
  }
  @at-root #{$self}__4 {
    @at-root #{$self}__item {
      @media (max-width: 1050px) {
        flex: 0 0 50%;
        width: 50%;
        margin-bottom: px_to_rem(40);
        &:nth-child(2) {
          border-right: px_to_rem(0.5) solid $color_gray_139;
        }
      }
      @media (max-width: 700px) {
        flex: 0 0 100%;
        width: 100%;
        padding: px_to_rem(30) 0 px_to_rem(30);
        border-left: 0;
        border-right: 0;
        border-top: px_to_rem(0.5) solid $color_gray_139;
        &:nth-child(2n) {
          border-right: 0;
        }
        &:last-of-type {
          border-bottom: px_to_rem(0.5) solid $color_gray_139;
        }
      }
    }
  }
}
</style>
