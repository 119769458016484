<template>
  <div class="c_related_products c_box--margin" :is="elementType">
    <div class="c_wrapper">
      <h2 class="c_header c_header--2 c_text--margin--bottom" v-if="header" v-html="header">{{ header }}</h2>
      <div class="c_related_products__cards" :style="{ '--related-card-count': relatedEntries.length }">
        <Card
          class="c_related_products__cards__card"
          :href="item.uri"
          :image-options="{
            alt: item.productFeaturedImage[0].title,
            sources: [
              { srcset: item.productFeaturedImage[0].filterSquare.srcsetWebp, type: 'image/webp' },
              { srcset: item.productFeaturedImage[0].filterSquare.srcset },
            ],
          }"
          :title="item.title"
          :description="item.relatedEntryDescription"
          v-if="item.typeHandle == 'productPage' && item.productFeaturedImage.length"
          v-for="item in relatedEntries"
          :key="item.uri"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Card from 'Components/card/Card.vue';
export default {
  components: {
    Card,
  },
  data() {
    return {};
  },
  props: {
    elementType: {
      type: String,
      default: 'div',
    },
    header: String,
    relatedEntries: Array,
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style lang="scss">
.c_related_products {
  $self: &;
  @at-root #{$self}__cards {
    display: grid;
    grid-template-columns: repeat(var(--related-card-count), 260px);
    gap: $grid_gap;
    @at-root .app--isEdge15orDown & {
      display: flex;
      flex-flow: row nowrap;
    }
    @include horizontal_scroll(false);
    padding: 5px 0 17px;

    @at-root .app--isEdge15orDown & {
      & > * {
        max-width: 100%;
        flex: 0 0 px_to_rem(260);
      }
    }
  }
}
</style>
