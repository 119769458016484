<template>
  <div class="product_tab_content c_wrapper c_box--width">
    <div class="product_tab_wrapper">
      <h2
        class="c_header c_header--6 c_text--margin--bottom product_tab_content__tab_header"
        v-if="tab.tabHeader"
        v-html="tab.tabHeader"
      />
      <AccordionTab
        v-if="tab.contentTab"
        v-for="(block, index) in tab.contentTab"
        :key="index"
        :mobile="$mq == 'sm'"
        :open-action="block.typeHandle == 'imageGallery' || block.typeHandle == 'videos' ? onOpenReloadSlider : null"
        :toggle-self="$mq == 'sm'"
        :disabled="!block.header || $mq != 'sm'"
        :class="[
          index == 0 ? 'c_accordion_tab--first' : '',
          index == tab.contentTab.length - 1 ? 'c_accordion_tab--last' : '',
        ]"
      >
        <div v-if="block.header" slot="header">{{ block.header }}</div>
        <div slot="content">
          <div v-if="block.typeHandle == 'resources'" class="product_tab_content__resources">
            <h3 v-if="block.header && $mq != 'sm'" class="c_header--5 c_text--uppercase c_header--underline">
              {{ block.header }}
            </h3>
            <Resources :resources="block.resources"></Resources>
          </div>
          <div v-else-if="block.typeHandle == 'text'" class="product_tab_content__text">
            <h3
              v-if="block.header && $mq != 'sm'"
              class="c_header--5 c_text--uppercase product_tab_content__header product_tab_content__text__header"
            >
              {{ block.header }}
            </h3>
            <div v-if="block.text" class="product_tab_content__text__text c_text" v-html="block.text"></div>
            <div :class="{ c_buttons: $mq === 'sm', 'c_buttons--right': $mq === 'sm' }" v-if="block.links">
              <div class="product_tab_content__text__link" v-for="(link, linkIndex) in block.links" :key="linkIndex">
                <LinkField
                  :button-attrs="{ color: 'black', outline: true }"
                  :super-table-field="link"
                  v-if="$mq === 'sm'"
                />
                <LinkField
                  class="c_text--uppercase"
                  :super-table-field="link"
                  component-type="arrow"
                  v-if="$mq !== 'sm'"
                />
              </div>
            </div>
          </div>
          <div
            v-else-if="block.typeHandle === 'imageGallery'"
            class="product_tab_content__image_gallery"
            :class="$mq === 'sm' ? 'c_box--break_out' : ''"
          >
            <h3
              v-if="block.header && $mq !== 'sm'"
              class="c_header--5 c_text--uppercase product_tab_content__header product_tab_content__image_gallery__header"
            >
              {{ block.header }}
            </h3>
            <GalleryGrid
              :images="formatSliderObject(block.images)"
              :slide-layout="block.galleryLayout"
              :arrows="$mq === 'sm' ? 'overlay' : 'outside'"
              :rounded="$mq !== 'sm'"
            ></GalleryGrid>
            <!--
              <GalleryGrid
                :images="galleryGridSources(block.images)"
                :slide-layout="block.layout"
                :arrow-color="block.colors == 'whiteTextBlackBackground' ? 'white' : 'black'"
              />
            -->
          </div>
          <div v-else-if="block.typeHandle === 'videos'" class="product_tab_content__videos">
            <h3
              v-if="block.header && $mq !== 'sm'"
              class="c_header--5 c_text--uppercase product_tab_content__header product_tab_content__videos__header"
            >
              {{ block.header }}
            </h3>
            <GalleryVideo :tabs="[{ videos: block.videos }]" />
          </div>
        </div>
      </AccordionTab>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from '@nuxtjs/composition-api';
import { log } from 'JS/global.js';
import AccordionTab from 'Components/accordion/AccordionTab.vue';
import GalleryGrid from 'Components/gallery/GalleryGrid.vue';
import GalleryVideo from 'Components/gallery/GalleryVideo.vue';
import LinkField from 'Components/link_field/LinkField.vue';
import Resources from 'Components/resources/Resources.vue';

export default defineComponent({
  name: 'ProductTabContent',
  components: {
    AccordionTab,
    LinkField,
    GalleryGrid,
    GalleryVideo,
    Resources,
  },
  props: {
    tabData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      tab: {},
    });

    state.tab = props.tabData;

    return { ...toRefs(state) };
  },
  methods: {
    formatSliderObject(imageObject) {
      const returnedImageArray = [];
      imageObject.forEach((item) => {
        returnedImageArray.push({
          sourcesMain: [
            { srcset: item.galleryEnlarged.srcsetWebp, type: 'image/webp' },
            { srcset: item.galleryEnlarged.srcset },
          ],
          sourcesThumbnails: [
            { srcset: item.galleryThumbnail.srcsetWebp, type: 'image/webp' },
            { srcset: item.galleryThumbnail.srcset },
          ],
          sourcesOverlay: [
            { srcset: item.galleryEnlarged.srcsetWebp, type: 'image/webp' },
            { srcset: item.galleryEnlarged.srcset },
          ],
          title: item.title,
        });
      });
      return returnedImageArray;
    },
    onOpenReloadSlider(thisAccordionTab) {
      // log('thisAccordionTab', thisAccordionTab.$children);
      for (let i = 0; i < thisAccordionTab.$children.length; i++) {
        if ('galleryGridSlider' in thisAccordionTab.$children[i].$refs) {
          // log('inside refs');
          thisAccordionTab.$children[i].reload();
        } else if (
          'main' in thisAccordionTab.$children[i].$refs &&
          'thumbnails' in thisAccordionTab.$children[i].$refs
        ) {
          thisAccordionTab.$children[i].reloadAll();
        } else if ('main' in thisAccordionTab.$children[i].$refs) {
          thisAccordionTab.$children[i].reloadMain();
        } else if ('thumbnails' in thisAccordionTab.$children[i].$refs) {
          thisAccordionTab.$children[i].reloadThumbnails();
        }
      }
    },
  },
  mounted() {
    log('ProductTabContent loaded');
  },
});
</script>

<style lang="scss">
.product_tab_content {
  $self: &;
  @include mq_max($mq_sm_to_md) {
    --wrapper-padding: 0;
  }
  @at-root #{$self}__tab_header {
    padding: 4vw 4vw 0 4vw;
    @include mq($mq_sm_to_md) {
      padding: 0;
    }
  }
  @at-root #{$self}__header {
    padding-bottom: px_to_rem(20);
  }
  @at-root #{$self}__text {
    @include mq($mq_sm_to_md) {
      padding-bottom: px_to_rem(35);
    }
    @at-root #{&}__header {
    }
    @at-root #{&}__text {
      padding-bottom: px_to_rem(20);
    }
    @at-root #{&}__link {
      margin-bottom: px_to_rem(24);
    }
  }
  @at-root #{$self}__resources {
    @include mq($mq_sm_to_md, '', 'rem') {
      padding-bottom: px_to_rem(63);
    }
  }
  @at-root #{$self}__image_gallery {
    padding-top: px_to_rem(20);
    padding-bottom: px_to_rem(40);
    .c_accordion_tab--disabled & {
      padding-top: px_to_rem(40);
    }
    .c_accordion_tab--first & {
      padding-top: 0;
    }
    @include mq($mq_sm_to_md) {
      padding: 0;
      padding-bottom: px_to_rem(80);
    }
  }
  @at-root #{$self}__videos {
    padding: px_to_rem(40) 0;
    .c_accordion_tab--first & {
      padding-top: 0;
    }
    .c_accordion_tab--last & {
      padding-bottom: 0;
    }
    @include mq($mq_sm_to_md) {
      padding-top: 0;
      padding-bottom: px_to_rem(80);
    }
  }
}
</style>
