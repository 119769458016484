<template>
  <div class="product_tab_compare c_wrapper" v-if="tab">
    <div class="product_tab_wrapper">
      <h2 class="c_header c_header--6 c_text--margin--bottom" v-if="tab.tabHeader" v-html="tab.tabHeader" />
      <div class="c_table" :style="{ '--table-min-width': tableMinWidth }">
        <table v-if="productData.length">
          <tr class="c_box--white">
            <td class="product_tab_compare--middle">
              <h2
                class="c_header"
                :class="$mq == 'sm' ? ['c_header--3', 'c_header--graphik_semibold'] : 'c_header--6'"
                v-if="tab.tableTitle"
                v-html="tab.tableTitle"
              />
            </td>
            <td class="product_tab_compare__product" v-for="(row, index) in tab.comparisonTable" :key="index">
              <mq-layout mq="md+" class="product_tab_compare__product__image">
                <MediaImage
                  background
                  :alt="row.product[0].productFeaturedImage[0].title"
                  :sources="[
                    { srcset: row.product[0].productFeaturedImage[0].filterSquare.srcsetWebp, type: 'image/webp' },
                    { srcset: row.product[0].productFeaturedImage[0].filterSquare.srcset },
                  ]"
                />
              </mq-layout>
              <h3 class="c_header c_header--3 c_header--graphik_semibold c_text--center">{{ row.product[0].title }}</h3>
            </td>
          </tr>
          <tr v-for="(label, labelIndex) in labels" :key="labelIndex">
            <td class="product_tab_compare--middle" v-html="label.labels"></td>
            <td
              v-for="(product, productIndex) in productData"
              :key="productIndex"
              v-html="product[labelIndex].information"
            ></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from '@nuxtjs/composition-api';
import { log } from 'JS/global.js';
import MediaImage from 'Components/image/MediaImage.vue';

export default defineComponent({
  name: 'ProductTabCompare',
  components: {
    MediaImage,
  },
  props: {
    tabData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      tab: {},
      labels: [],
      productData: [],
    });

    state.tab = props.tabData;

    // Parse data to make it easier to work with later. The length of `productData` should match the length of `tab.comparisonTable` so indexes match up.
    if (state.tab.comparisonLabelsParsed) {
      state.labels = JSON.parse(state.tab.comparisonLabelsParsed);

      state.tab.comparisonTable.forEach((row) => {
        state.productData.push(JSON.parse(row.rowsParsed));
      });
    }

    return { ...toRefs(state) };
  },
  computed: {
    tableMinWidth() {
      if (this.tab.comparisonTable) {
        return this.$mq === 'sm'
          ? `${this.tab.comparisonTable.length * 150 + 200}px`
          : `${this.tab.comparisonTable.length * 350 + 370}px`;
      }
      return false;
    },
  },
  mounted() {
    log('ProductTabCompare loaded');
  },
});
</script>

<style lang="scss">
.product_tab_compare {
  $self: &;
  --table-label-width: 370px;
  --table-td-width: 370px;

  @include mq_max($mq_sm_to_md) {
    --wrapper-padding: 0;
  }

  @at-root #{$self}--middle {
    vertical-align: middle;
  }
  @at-root #{$self}__product {
    padding: 15px !important;

    @include mq($mq_sm_to_md) {
      padding: 50px !important;
    }

    @at-root #{&}__image {
      margin-bottom: 10px;
      height: 220px;
    }
  }
  @at-root #{$self}__disclosure {
    padding: 14px;
  }
}
</style>
