<template>
  <div
    class="c_text_and_image"
    :style="[
      isBackgroundImage && data.image[0]
        ? {
            backgroundImage: getBackgroundImage,
            backgroundPosition: getBackgroundPosition,
          }
        : '',
    ]"
    :class="[getContainerClasses, data.layoutStyle]"
    ref="c_text_and_image"
  >
    <RelativeAnchor :id="data.pageTitle" />
    <div class="c_wrapper">
      <div :class="layoutStyle" :style="{ marginLeft: getTextPosition }">
        <mq-layout
          mq="md+"
          class="c_text_and_image__large_img"
          :style="{
            backgroundImage: 'url(' + data.image[0].lpBackgroundWide.src + ')',
            backgroundPosition: getBackgroundPosition,
          }"
          v-if="data.image[0]?.lpBackgroundWide && largeImg"
        >
        </mq-layout>
        <div
          class="c_wrapper__background_color"
          :class="{ 'c_text--black': forceBlackText, 'c_text--white': forceWhiteText }"
        >
          <div :class="layoutStyle + '__image'">
            <MediaImage
              v-if="data.image[0]?.lpImageTextUncropped"
              :sources="formatSources(data.image[0])"
              :alt="data.image[0].title"
            />
            <img
              v-if="!data.image[0]?.lpImageTextUncropped"
              :src="data.image[0]?.url"
              :alt="data.image[0]?.title"
              class="c_image"
            />
            <h2 class="c_2022--h2_graphik" v-if="data.label && data.layoutStyle === 'iconLeftTextRight'">
              {{ data.label }}
            </h2>
          </div>
          <div :class="layoutStyle + '__text_container'">
            <h2 class="c_2022--h2_graphik" v-if="data.label && data.layoutStyle !== 'iconLeftTextRight'">
              {{ data.label }}
            </h2>
            <div class="c_2022--body" v-if="data.description" v-html="data.description"></div>
            <div class="c_text_and_image__button_container" v-if="data.ctaButton">
              <Button
                v-for="(button, index) in data.ctaButton"
                unstyle
                reset
                :key="index"
                :is="'a'"
                :aria-label="button.ariaLabel"
                :href="getHrefs(button)"
                :target="getTarget(button)"
                class="c_button_2022"
                :class="buttonClasses(button)"
              >
                {{ button.buttonLabel }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaImage from 'Components/image/MediaImage.vue';
import Button from 'Components/button/Button.vue';
import { openPdfsInNewTab } from 'JS/global.js';

export default {
  components: {
    RelativeAnchor: () => import('Components/relative_anchor/RelativeAnchor.vue'),
    MediaImage,
    Button,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      layoutStyle: this.data.layoutStyle,
      whiteBg: false,
      largeImg: false,
    };
  },
  computed: {
    isBackgroundImage() {
      let display = false;
      switch (this.layoutStyle) {
        case 'textRightBackgroundImage':
          display = true;
          break;
        case 'textLeftBackgroundImage':
          display = true;
          break;
      }
      return display;
    },
    hasFade() {
      return this.isBackgroundImage && this.data.backgroundOpacity > 0.0;
    },
    getBackgroundImage() {
      let img = 'url(' + this.data.image[0].lpBackgroundWide.src + ')';
      if (this.hasFade) {
        const opacity = this.data.backgroundOpacity;
        let direction = 'to left';
        if (this.data.layoutStyle === 'textLeftBackgroundImage') {
          direction = 'to right';
        }
        img = `linear-gradient(${direction}, black, rgba(0, 0, 0, ${opacity})), ${img}`;
      }
      return img;
    },
    getBackgroundPosition() {
      let backgroundPositionX = '50%';
      let backgroundPositionY = 'center';
      if (this.data.backgroundVerticalPosition !== undefined) {
        backgroundPositionX = this.data.backgroundVerticalPosition + '%';
      }
      if (this.data.backgroundHorizontalPosition) {
        backgroundPositionY = this.data.backgroundHorizontalPosition;
      }
      return backgroundPositionY + ' ' + backgroundPositionX;
    },
    getTextPosition() {
      let left = null;
      if (this.layoutStyle === 'textRightBackgroundImage') {
        left = 'auto';
        return left;
      }
      return null;
    },
    getContainerClasses() {
      return {
        c_text_and_image__has_background: this.isBackgroundImage,
        c_text_and_image__has_fade: this.hasFade,
      };
    },
    forceBlackText() {
      return this.isBackgroundImage && !this.hasFade;
    },
    forceWhiteText() {
      return this.isBackgroundImage && this.hasFade;
    },
  },
  created() {
    switch (this.layoutStyle) {
      case 'imageLeftTextRight':
        this.whiteBg = true;
        break;
      case 'textLeftImageRight':
        this.whiteBg = true;
        break;
      case 'textLargeImage':
        this.whiteBg = true;
        this.largeImg = true;
        break;
      case 'largeImageText':
        this.whiteBg = true;
        this.largeImg = true;
        break;
    }
  },
  methods: {
    formatSources(image) {
      const returnSources = [
        { srcset: image.lpImageTextUncropped.srcWebp, type: 'webp' },
        { srcset: image.lpImageTextUncropped.srcset },
      ];
      return returnSources;
    },
    buttonClasses(button) {
      let btnClasses = '';
      button.buttonStyling === 'primary'
        ? (btnClasses = 'c_button_2022__primary')
        : (btnClasses = 'c_button_2022__secondary c_button_2022--text_black');
      return btnClasses;
    },
    getHrefs(btn) {
      // Return URL of entry or asset
      if (!btn.custom?.length) {
        return btn.entry?.length ? this.localePath(`/${btn?.entry[0]?.uri}`) : btn.asset[0]?.url;
      }
      // Otherwise return custom URL, or null if no value exists
      return btn.custom || null;
    },
    getTarget(btn) {
      const href = this.getHrefs(btn);
      if (typeof href === 'string' && href.includes('.pdf')) {
        return '_blank';
      }
      return btn.openInANewWindow ? '_blank' : null;
    },
  },
  mounted() {
    openPdfsInNewTab(this.$refs.c_text_and_image);
  },
};
</script>

<style lang="scss">
.c_text_and_image {
  $self: &;

  padding: 0;
  background-size: cover;
  @media (max-width: $mq_sm_to_md + 'px') {
    background-image: none !important;
  }
  @include mq($mq_sm_to_md) {
    padding: px_to_rem(100) 0;
  }
  &.iconLeftTextRight {
    padding: 0;
    @include mq($mq_sm_to_md) {
      padding: px_to_rem(25) 0;
    }
  }
  ul,
  ol {
    li a {
      color: red;
    }
  }
  .c_2022--body p + ul {
    margin-top: 15px; /* Adjust the value as needed */
  }
  .c_wrapper {
    ol {
      padding-left: px_to_rem(15);
      margin-bottom: px_to_rem(15);
    }
    ul {
      margin-bottom: px_to_rem(15);
      padding-left: px_to_rem(15);
      li {
        list-style-type: disc;
      }
    }
    p {
      margin-bottom: px_to_rem(15);
      &:last-of-type {
        margin-bottom: 0;
      }
      a {
        color: $color_red;
        &:hover {
          text-decoration: none;
        }
      }
    }
    strong {
      @include font('graphik_semibold');
    }
    em {
      @include font('graphik_light_italic');
    }
  }
  .iconLeftTextRight {
    $iconLeftTextRight: &;
    background-color: $color_gray_230;
    padding: px_to_rem(15) px_to_rem(15);
    @include mq($mq_sm_to_md) {
      background-color: $color_white;
      padding: 0;
    }
    .c_wrapper__background_color {
      display: block;
      align-items: center;
      padding: px_to_rem(31) px_to_rem(35) px_to_rem(67) px_to_rem(35);
      background-color: $color_white;
      @at-root #{$iconLeftTextRight}__image {
        display: flex;
        align-items: center;
        margin-bottom: px_to_rem(20);
        .c_image {
          display: block;
          width: px_to_rem(50);
          flex: 0 0 px_to_rem(50);
          margin-right: px_to_rem(20);
        }
      }
      @at-root #{$iconLeftTextRight}__text_container {
        display: flex;
        align-items: center;
        padding-left: 0;
      }
      h2 {
        @include font('graphik_semibold');
        @include mq($mq_md_to_lg) {
          min-width: px_to_rem(340px);
          flex: 1 0 100%;
          padding-right: px_to_rem(65);
        }
      }
      @include mq($mq_md_to_lg) {
        display: flex;
        h2 {
          flex: 0 0 15%;
        }
        @at-root #{$iconLeftTextRight}__image {
          margin-bottom: px_to_rem(0);
          .c_image {
            width: px_to_rem(75);
            flex: 0 0 px_to_rem(75);
          }
        }
        @at-root #{$iconLeftTextRight}__text_container {
          padding-left: px_to_rem(35);
        }
      }
      @include mq($mq_sm_to_md) {
        background-color: $color_white;
        padding: px_to_rem(50);
      }
    }
  }
  .textLeftBackgroundImage,
  .textRightBackgroundImage {
    $backgroundImageContainer: &;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    background-color: $color_white;
    [class$='__text_container'] {
      padding: px_to_rem(31) px_to_rem(48) px_to_rem(67) px_to_rem(35);
    }
    @at-root #{$self}__has_fade {
      .textLeftBackgroundImage,
      .textRightBackgroundImage {
        background-color: unset;
      }
    }
    h2 {
      @include font('graphik_semibold');
      padding: 0;
      min-width: 0;
      margin-bottom: px_to_rem(40);
    }
    @at-root #{&}__image {
      img {
        display: block;
        width: 100%;
        height: auto;
        @include mq($mq_sm_to_md) {
          display: none;
        }
      }
    }
    @include mq($mq_sm_to_md) {
      flex: 1 1 50%;
      width: 50%;
      min-width: px_to_rem(480px);
    }
    @include mq($mq_md_to_lg) {
      flex: 1 1 35%;
      width: 35%;
      h2 {
        margin-bottom: px_to_rem(85);
      }
    }
  }
  .imageLeftTextRight,
  .textLeftImageRight {
    $imgLeftTextRight: &;
    .c_wrapper__background_color {
      display: block;
      padding: 0;
      @at-root #{$self}__image {
        flex: 0 0 100%;
        width: 100%;
        align-content: center;
        justify-content: center;
        img {
          width: 100%;
        }
      }
      .imageLeftTextRight__text_container,
      .textLeftImageRight__text_container {
        flex: 0 0 100%;
        padding: px_to_rem(35);
        h2 {
          @include font('graphik_semibold');
          padding: 0;
          min-width: 0;
          margin-bottom: px_to_rem(40);
        }
        p {
          margin-bottom: px_to_rem(15);
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      @include mq($mq_md_to_lg) {
        .imageLeftTextRight__text_container,
        .textLeftImageRight__text_container {
          h2 {
            margin-bottom: px_to_rem(85);
          }
        }
      }
      @include mq($mq_sm_to_md) {
        display: flex;
        padding: 0;
        .imageLeftTextRight__text_container,
        .textLeftImageRight__text_container {
          padding-left: px_to_rem(40);
          padding-top: 0;
          padding-bottom: 0;
          flex: 0 0 50%;
        }
      }
    }
  }
  .textLeftImageRight {
    .c_wrapper__background_color {
      .textLeftImageRight__text_container {
        order: 1;
        @include mq($mq_sm_to_md) {
          padding-left: 0;
        }
      }
      .textLeftImageRight__image {
        order: 2;
      }
    }
  }
  &.largeImageText,
  &.textLargeImage {
    padding: 0;
    .c_wrapper {
      padding: 0;
      max-width: px_to_rem(1920);
      width: 100%;
      .largeImageText,
      .textLargeImage {
        display: flex;
        h2 {
          margin-bottom: px_to_rem(20);
          @include font('graphik_semibold');
          @include mq($mq_sm_to_md) {
            margin-bottom: px_to_rem(35);
          }
        }
        .c_image {
          display: block;
          @include mq($mq_sm_to_md) {
            display: none;
          }
        }
        .c_text_and_image__large_img {
          background-size: cover;
          width: 100%;
          @include mq($mq_sm_to_md) {
            width: 50%;
            flex: 0 0 50%;
          }
        }
        .c_wrapper__background_color {
          width: 100%;
          padding: 0;
          @include mq($mq_sm_to_md) {
            width: 50%;
            flex: 1 1 50%;
            padding: px_to_rem(100) px_to_rem(45);
          }
          @include mq($mq_md_to_lg) {
            padding: px_to_rem(200) px_to_rem(45);
          }
        }
        .largeImageText__text_container,
        .textLargeImage__text_container {
          padding: px_to_rem(35);
          max-width: none;
          @include mq($mq_sm_to_md) {
            max-width: px_to_rem(500);
            padding: 0;
          }
        }
      }
    }
  }
  &.textLargeImage {
    .c_text_and_image__large_img {
      order: 2;
    }
    .c_wrapper__background_color {
      order: 1;
      margin-left: 0;
      max-width: none;
      @include mq($mq_sm_to_md) {
        margin-left: auto;
        max-width: px_to_rem(530);
      }
    }
  }
  .c_wrapper {
    @media (max-width: $mq_sm_to_md + 'px') {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @at-root #{&}__button_container {
    a {
      margin-right: px_to_rem(20);
      margin-top: px_to_rem(20);
    }
  }
}
</style>
