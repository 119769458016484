var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.elementType,{tag:"div",staticClass:"c_related_products c_box--margin"},[_c('div',{staticClass:"c_wrapper"},[(_vm.header)?_c('h2',{staticClass:"c_header c_header--2 c_text--margin--bottom",domProps:{"innerHTML":_vm._s(_vm.header)}},[_vm._v(_vm._s(_vm.header))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"c_related_products__cards",style:({ '--related-card-count': _vm.relatedEntries.length })},_vm._l((_vm.relatedEntries),function(item){return (item.typeHandle == 'productPage' && item.productFeaturedImage.length)?_c('Card',{key:item.uri,staticClass:"c_related_products__cards__card",attrs:{"href":item.uri,"image-options":{
          alt: item.productFeaturedImage[0].title,
          sources: [
            { srcset: item.productFeaturedImage[0].filterSquare.srcsetWebp, type: 'image/webp' },
            { srcset: item.productFeaturedImage[0].filterSquare.srcset },
          ],
        },"title":item.title,"description":item.relatedEntryDescription}}):_vm._e()}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }