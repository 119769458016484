var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"product_tab_related c_wrapper"},[_c('div',{staticClass:"product_tab_wrapper"},[_c('header',{staticClass:"product_tab_related__header c_text--margin--bottom"},[_c('div',[(_vm.tab.tabHeader)?_c('h2',{staticClass:"c_header c_header--6 c_text--margin",domProps:{"innerHTML":_vm._s(_vm.tab.tabHeader)}}):_vm._e()]),_vm._v(" "),_c('div',[_c('SelectControl',{attrs:{"select-options":[
            { label: 'Newest', value: 'newest' },
            { label: 'Oldest', value: 'oldest' },
          ]},on:{"selectOptionChange":_vm.updateFilterValue}})],1)]),_vm._v(" "),(_vm.pagedResults.length)?_c('div',{staticClass:"product_tab_related__grid c_grid c_grid--2-3"},_vm._l((_vm.pagedResults),function(item,index){return _c('Card',{key:index,attrs:{"image-full-width":"","date":item.postDate,"href":item.uri,"image-options":{
          alt: item.blogFeaturedImage[0].title,
          sources: [
            { srcset: item.blogFeaturedImage[0].relatedEntries.srcsetWebp, type: 'image/webp' },
            { srcset: item.blogFeaturedImage[0].relatedEntries.srcset },
          ],
        },"title":item.title,"description":item.articleSummary}})}),1):_vm._e(),_vm._v(" "),_c('PaginationControl',{ref:"pagination",staticClass:"c_box--margin--top",attrs:{"items-to-page":_vm.orderedEntries,"justify-content":"flex-start"},on:{"pagedItemsUpdated":_vm.updatePagedResults}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }