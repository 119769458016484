<template>
  <div class="product_tab_related c_wrapper">
    <div class="product_tab_wrapper">
      <header class="product_tab_related__header c_text--margin--bottom">
        <div>
          <h2 class="c_header c_header--6 c_text--margin" v-if="tab.tabHeader" v-html="tab.tabHeader" />
        </div>
        <div>
          <SelectControl
            :select-options="[
              { label: 'Newest', value: 'newest' },
              { label: 'Oldest', value: 'oldest' },
            ]"
            @selectOptionChange="updateFilterValue"
          />
        </div>
      </header>
      <div class="product_tab_related__grid c_grid c_grid--2-3" v-if="pagedResults.length">
        <Card
          image-full-width
          :date="item.postDate"
          :href="item.uri"
          :image-options="{
            alt: item.blogFeaturedImage[0].title,
            sources: [
              { srcset: item.blogFeaturedImage[0].relatedEntries.srcsetWebp, type: 'image/webp' },
              { srcset: item.blogFeaturedImage[0].relatedEntries.srcset },
            ],
          }"
          :title="item.title"
          :description="item.articleSummary"
          v-for="(item, index) in pagedResults"
          :key="index"
        />
      </div>
      <PaginationControl
        class="c_box--margin--top"
        :items-to-page="orderedEntries"
        justify-content="flex-start"
        ref="pagination"
        @pagedItemsUpdated="updatePagedResults"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, useContext, useFetch, useStore } from '@nuxtjs/composition-api';
import { log, selectedCategoriesFromCategoriesField } from 'JS/global.js';
import { relatedBlogAndPressReleasesGql } from 'GQL/relatedBlogAndPressReleasesGql.js';
import Card from 'Components/card/Card.vue';
import PaginationControl from 'Components/pagination_control/PaginationControl.vue';
import SelectControl from 'Components/select_control/SelectControl.vue';

export default defineComponent({
  name: 'ProductTabRelated',
  components: {
    Card,
    PaginationControl,
    SelectControl,
  },
  props: {
    tabData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { app, $craft } = useContext();
    const store = useStore();
    const state = reactive({
      filterValue: 'newest',
      pagedResults: [],
      relatedEntries: [],
      tab: {},
    });

    state.tab = props.tabData;

    if (state.tab.parent?.productCategories) {
      // todo Fold this into Product tab GQL
      useFetch(async () => {
        const relations = selectedCategoriesFromCategoriesField(state.tab.parent.productCategories);
        const request = await $craft({
          apiLog: 'ProductTabRelated',
          query: relatedBlogAndPressReleasesGql,
          variables: {
            relations,
            sections: 'blog',
            site: store.state.global.localeSites[app.i18n.locale].siteHandle,
          },
        });

        if (request.data?.entries) {
          state.relatedEntries = request.data.entries;
        }
      });
    }

    return { ...toRefs(state) };
  },
  computed: {
    queryRelations() {
      if (Object.keys(this.tab)) {
        return selectedCategoriesFromCategoriesField(this.tab.parent.productCategories);
      }
      return [];
    },
    orderedEntries() {
      if (this.filterValue === 'newest') {
        return this.relatedEntries.slice();
      } else if (this.filterValue === 'oldest') {
        return this.relatedEntries.slice().reverse();
      }
      return this.relatedEntries;
    },
  },
  methods: {
    updateFilterValue(newValue) {
      this.filterValue = newValue;
      this.$refs.pagination.reset();
    },
    updatePagedResults() {
      this.pagedResults = this.$refs.pagination.currentPageResults;
    },
  },
  mounted() {
    log('ProductTabRelated loaded');
  },
});
</script>

<style lang="scss">
.product_tab_related {
  $self: &;

  @at-root #{$self}__header {
    display: grid;
    grid-template-columns: auto max-content;
    align-items: center;
  }
  @at-root #{$self}__grid {
    @include mq(400) {
      --grid-gap: 47px 14px;
    }
  }
}
</style>
