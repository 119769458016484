<template>
  <client-only>
    <div class="c_video_banner_2022 c_text--left c_box--break_out">
      <RelativeAnchor v-if="displayInQuickLinks && pageTitle" :id="pageTitle" />
      <mq-layout mq="md+">
        <MediaImage
          v-if="image && !backgroundVideo[0]"
          background
          class="c_video_banner_2022__image"
          :sources="formatSources(image)"
          :alt="image.title"
        />
        <video
          v-if="backgroundVideo[0]"
          :id="backgroundVideo[0].id"
          :ref="backgroundVideo[0].id"
          class="c_video_banner_2022__video"
          autoplay
          muted
          loop
          :poster="image.url"
        >
          <source :src="backgroundVideo[0].url" type="video/mp4" />
        </video>
        <div class="c_wrapper c_video_banner_2022__content c_video_banner_2022__content__flex">
          <h3 v-if="header" class="c_header c_text--graphik_regular c_2022--h3_graphik" v-html="header"></h3>
          <Button
            @onClick="image.videoId ? $refs.videoBannerOverlay.show() : null"
            class="c_text--black c_2022--body"
            unstyle
            reset
            v-if="image.videoId"
            :aria-label="$t('Open media in popup overlay')"
          >
            <IconSVG width="24" height="24" handle="chevron_right" color="black" aria-hidden="true" />
            <span v-if="buttonText">{{ buttonText }}</span>
            <span v-else>{{ $t('Watch Video') }}</span>
          </Button>
          <span class="c_video_banner_2022__content__duration c_2022--body" v-if="videoDuration">
            {{ videoDuration }}
          </span>
          <div class="c_video_banner_2022__content__text c_2022--body" v-if="text" v-html="text"></div>
        </div>
        <div class="c_video_banner_2022__image__overlay"></div>
      </mq-layout>
      <mq-layout mq="sm">
        <div class="c_video_banner_2022__media_container">
          <MediaImage
            v-if="image"
            background
            class="c_video_banner_2022__image"
            :sources="formatSources(image)"
            :alt="image.title"
          />
          <div class="c_video_banner_2022__content c_wrapper">
            <Button
              @onClick="image.videoId ? $refs.videoBannerOverlay.show() : null"
              class="c_text--black c_2022--body"
              unstyle
              reset
              :aria-label="$t('Open media in popup overlay')"
              v-if="image.videoId"
            >
              <IconSVG width="24" height="24" handle="chevron_right" color="black" aria-hidden="true"></IconSVG>
              <span v-if="buttonText">{{ buttonText }}</span>
              <span v-else>{{ $t('Watch Video') }}</span>
            </Button>
            <span class="c_video_banner_2022__content__duration_mobile c_2022--caption" v-if="videoDuration">
              {{ videoDuration }}
            </span>
          </div>
          <div class="c_video_banner_2022__image__overlay"></div>
        </div>
        <div class="c_video_banner_2022__mobile_content c_wrapper">
          <h3 v-if="header" class="c_header c_text--graphik_regular c_2022--h3_graphik" v-html="header"></h3>
          <div class="c_2022--body" v-if="text" v-html="text"></div>
        </div>
      </mq-layout>
      <Overlay
        class="c_video_banner_2022__overlay"
        ref="videoBannerOverlay"
        theme="full"
        :show-close-button="true"
        v-if="image.videoId"
      >
        <MediaVideo
          v-if="image.videoId"
          loading="eager"
          :source="image.videoSource ? image.videoSource : 'youtube'"
          :video-id="image.videoId"
        />
      </Overlay>
    </div>
  </client-only>
</template>

<script>
import Button from 'Components/button/Button.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';
import Overlay from 'Components/overlay/Overlay.vue';
import MediaImage from 'Components/image/MediaImage.vue';
import MediaVideo from 'Components/video/MediaVideo.vue';
import RelativeAnchor from 'Components/relative_anchor/RelativeAnchor.vue';
export default {
  components: {
    Button,
    IconSVG,
    MediaImage,
    MediaVideo,
    Overlay,
    RelativeAnchor,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
    imageOptimizedFields: {
      type: Array,
      required: true,
    },
    backgroundVideo: Array,
    header: String,
    buttonText: { type: String, default: 'Watch Video' },
    videoDuration: String,
    text: String,
    displayInQuickLinks: Boolean,
    pageTitle: String,
  },
  methods: {
    formatSources(image) {
      const returnArray = []; // originally let
      for (let i = 0; i < this.imageOptimizedFields.length; i++) {
        returnArray.push({ srcset: image[this.imageOptimizedFields[i]]?.srcsetWebp, type: 'webp' });
        returnArray.push({ srcset: image[this.imageOptimizedFields[i]]?.srcset });
      }
      return returnArray;
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss">
@mixin absolute_fill() {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.c_video_banner_2022 {
  $self: &;
  display: block;
  overflow: hidden;
  position: relative;
  h3 {
    padding-bottom: px_to_rem(10);
    @include font('graphik_medium');
    @include mq($mq_sm_to_md) {
      padding-bottom: px_to_rem(50);
    }
    @include mq($mq_md_to_lg) {
      padding-bottom: px_to_rem(110);
    }
  }
  ul a,
  ol a {
    color: red;
  }
  a {
    color: red;
  }
  .c_wrapper {
    ol {
      padding-left: px_to_rem(15);
      margin-bottom: px_to_rem(15);
    }
    ul {
      margin-bottom: px_to_rem(15);
      padding-left: px_to_rem(15);
      li {
        list-style-type: disc;
      }
    }
    p {
      margin-bottom: px_to_rem(15);
      &:last-of-type {
        margin-bottom: 0;
      }
      a {
        color: $color_red;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  ul {
    list-style-type: disc;
  }
  @at-root #{$self}__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // background-position: center;
    @at-root #{&}__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color_black_raw, 0.5);
    }
  }
  @at-root #{&}__media_container {
    position: relative;
    min-height: px_to_rem(275);
    .c_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: px_to_rem(100);
      padding-bottom: px_to_rem(100);
    }
  }
  @at-root #{$self}__video {
    position: absolute;
    left: 50%;
    top: 50%;
    min-width: 100%;
    min-height: 100%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 0;
    object-fit: cover;
    /* background-position: center;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0; */
    // transform: translate(-50%, -50%);
    // overflow: hidden;
    // background-position: center;
    @at-root #{&}__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color_black_raw, 0.5);
    }
  }

  @at-root #{$self}__background {
    $background: &;
    @include absolute_fill();
    @at-root #{$background}__overlay {
      @include absolute_fill();
      background-color: black;
      opacity: var(--opacity);
      z-index: 2;
    }
    @at-root #{$background}__overlay.light {
      background-color: white;
    }
    @at-root #{$background}__visual {
      $visual: &;
      @include absolute_fill();
      background-color: transparent;
      z-index: 1;
      background-size: cover;
      background-position: center;
      overflow-x: hidden;
      overflow-y: hidden;
      /*c_hero_banner__background__visual__video{  tab in twice*/
      @at-root #{$visual}__video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -100;
        -ms-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        background-size: cover;
        overflow-x: hidden;
      }
    }
  }
  @at-root #{$self}__mobile_content {
    padding-top: px_to_rem(20);
    padding-bottom: px_to_rem(30);
  }
  @at-root #{$self}__content {
    $content: &;
    margin: 0 auto;
    position: relative;
    z-index: 50;
    color: $color_white;
    padding-top: px_to_rem(50);
    padding-bottom: px_to_rem(50);
    @include mq($mq_md_to_lg) {
      padding-top: px_to_rem(100);
      padding-bottom: px_to_rem(100);
    }
    @at-root #{&}__flex {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      min-height: px_to_rem(400);
      @include mq($mq_md_to_lg) {
        min-height: px_to_rem(600);
      }
    }
    @at-root #{&}__duration {
      margin-bottom: px_to_rem(30);
      @include mq($mq_md_to_lg) {
        margin-bottom: px_to_rem(60);
      }
    }
    @at-root #{&}__duration_mobile {
      position: absolute;
      @include font('graphik_regular');
      bottom: px_to_rem(0);
      right: px_to_rem(10);
      z-index: 5;
      background-color: $color_black;
      border-radius: px_to_rem(2);
      padding: px_to_rem(2) px_to_rem(3);
      color: $color_white;
    }
    @at-root #{&}__text {
      @include font('graphik_medium');
      max-width: px_to_rem(550);
      width: 100%;
      padding-bottom: px_to_rem(0);
      @include mq($mq_sm_to_md) {
        padding-bottom: px_to_rem(50);
      }
      @include mq($mq_md_to_lg) {
        padding-bottom: px_to_rem(100);
      }
      strong {
        font-family: 'Graphik-Semibold';
        display: block;
        margin-bottom: px_to_rem(10);
      }
    }
    button {
      display: flex;
      align-items: center;
      background-color: $color_yellow;
      border-radius: px_to_rem(20);
      padding: px_to_rem(18) px_to_rem(28);
      font-family: 'Graphik-Semibold';
      margin-bottom: px_to_rem(0);
      .c_icon_svg {
        margin-right: px_to_rem(24);
      }
      @include mq($mq_sm_to_md) {
        margin-bottom: px_to_rem(30);
      }
    }
  }
  @at-root #{$self}__overlay {
    .c_video {
      width: 90vw;
      max-width: px_to_rem(800);
    }
  }
}
</style>
