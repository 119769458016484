<template>
  <div class="c_gallery_video">
    <client-only>
      <Tabs
        class="c_gallery_video__tabs c_wrapper"
        :tabs="dataTabs"
        v-if="hasTabHeaders(dataTabs)"
        identifier="index"
        :active-identifier="activeTabIndex"
        @onClickTab="setTab"
      />
      <div class="c_gallery_video__videos__main" v-if="currentMediaItem">
        <div v-if="!showMainVideo" class="c_gallery_video__videos__main__image">
          <MediaImage
            background
            :alt="currentMediaItem.header"
            :sources="formatSliderObject(currentMediaItem)[0].sourcesThumbnails"
          />
          <Button
            reset
            unstyle
            class="c_box--mask c_gallery_video__videos__main__image__play_icon"
            @onClick="playMainVideo"
          >
            <IconSVG
              handle="media_play"
              width="60"
              height="60"
              :replacements="{ 'Media - Play': $t('Media - Play') }"
            />
            <div class="c_box--mask c_gallery_video__videos__main__image__play_icon__mask"></div>
          </Button>
        </div>
        <MediaVideo
          v-else
          :source="currentMediaItem.videoSource || 'youtube'"
          :title="currentMediaItem.header"
          :video-id="currentMediaItem.videoId"
          autoplay
        />
        <div
          class="c_gallery_video__videos__main__info"
          v-if="currentMediaItem.header || currentMediaItem.caption || currentMediaItem.descriptor"
        >
          <div class="c_gallery_video__videos__main__info__content">
            <h3 class="c_header c_header--3 c_text--graphik_semibold c_header--black" v-if="currentMediaItem.header">
              {{ currentMediaItem.header }}
            </h3>
            <p class="c_text--black" v-if="currentMediaItem.caption">
              {{ currentMediaItem.caption }}
            </p>
          </div>
          <p class="c_gallery_video__videos__main__info__descriptor" v-if="currentMediaItem.descriptor">
            {{ currentMediaItem.descriptor }}
          </p>
        </div>
      </div>
      <div
        class="c_gallery_video__videos"
        :class="$mq === 'sm' ? 'c_box--break_out' : ''"
        v-for="(tab, videosIndex) in dataTabs"
        v-show="activeTabIndex === videosIndex"
        :key="'c_gallery_video__videos--' + videosIndex"
      >
        <Slider
          :arrow-color="arrowColor"
          class="c_gallery_video__videos__slider"
          ref="videoGallery"
          main-height="620px"
          :arrows="tab.videos.length > 4 ? 'outside' : 'hidden'"
          :video-player="true"
          :show-indicators="false"
          thumbnails-height="260px"
          v-if="$mq === 'lg'"
        >
          <template #thumbnails>
            <Button
              class="c_slider__slide c_slider__slide--thumbnail c_gallery_video__videos__slider__slide"
              @onClick="updateCurrentVideoIndex(index)"
              v-for="(slide, index) in tab.videos"
              :key="index"
              v-show="slide.videoId"
              unstyle
              reset
            >
              <div class="c_gallery_video__videos__slider__slide__content">
                <div class="c_gallery_video__videos__slider__slide__content__image__mask"></div>
                <MediaImage
                  class="c_gallery_video__videos__slider__slide__content__image"
                  background
                  :alt="slide.header"
                  :sources="formatSliderObject(slide)[0].sourcesThumbnails"
                />
                <div
                  class="c_gallery_video__videos__slider__slide__content__info c_text"
                  v-if="slide.header || slide.caption"
                >
                  <h5
                    class="c_gallery_video__videos__slider__slide__content__info__header c_header c_text--graphik_semibold"
                    v-if="slide.header"
                  >
                    {{ truncateHeader(slide.header, slide.caption ? true : false) }}
                  </h5>
                  <p class="c_gallery_video__videos__slider__slide__content__info__subhead" v-if="slide.caption">
                    {{ truncateCaption(slide.caption, 50) }}
                  </p>
                </div>
              </div>
            </Button>
          </template>
        </Slider>
      </div>
      <div
        class="c_gallery_video__listing c_wrapper"
        v-show="activeTabIndex === videosIndex && $mq.includes(['sm', 'md']) && tab.videos.length > 1"
        v-for="(tab, videosIndex) in dataTabs"
        :key="'c_gallery_video__listing--' + videosIndex"
      >
        <Button
          class="c_gallery_video__listing__item"
          :unstyle="true"
          :reset="true"
          v-for="(video, index) in tab.videos.slice(0, videosShowingCount)"
          :key="index"
          @onClick="updateCurrentVideoIndex(index)"
        >
          <div class="c_gallery_video__listing__item__grid">
            <div class="c_gallery_video__listing__item__image__mask">
              <div class="c_box--mask"></div>
            </div>
            <MediaImage
              class="c_gallery_video__listing__item__image"
              background
              :alt="video.header"
              :sources="formatSliderObject(video)[0].sourcesThumbnails"
            />
            <div class="c_gallery_video__listing__item__info c_text" v-if="video.header || video.caption">
              <h5 class="c_gallery_video__listing__item__header c_header c_text--graphik_semibold" v-if="video.header">
                {{ video.header }}
              </h5>
              <p v-if="video.caption">{{ truncateCaption(video.caption, 100) }}</p>
            </div>
          </div>
        </Button>
        <div class="c_buttons c_buttons--center">
          <Button
            v-if="amountOfVideos && videosShowingCount < amountOfVideos"
            class="c_button"
            color="black"
            :outline="true"
            @onClick="buttonClickedIncrementCount"
            >More Videos</Button
          >
        </div>
      </div>
    </client-only>
  </div>
</template>

<script>
import Button from 'Components/button/Button.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';
import MediaImage from 'Components/image/MediaImage.vue';
import MediaVideo from 'Components/video/MediaVideo.vue';
import Slider from 'Components/slider/Slider.vue';
import Tabs from 'Components/tabs/Tabs.vue';

export default {
  components: {
    Button,
    IconSVG,
    MediaImage,
    MediaVideo,
    Slider,
    Tabs,
  },
  data() {
    return {
      amountOfVideos: 0,
      showVideosMultiplier: 1,
      showMainVideo: false,
      activeTabIndex: 0,
      currentVideoIndex: 0,
    };
  },
  props: {
    arrowColor: {
      type: String,
      default: 'black',
    },
    tabs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    videosShowingCount() {
      return this.showVideosMultiplier * 4;
    },
    dataTabs() {
      let returnTabs = [];
      for (let t = 0; t < this.tabs.length; t++) {
        const tab = this.tabs[t];

        if (this.hasImagesWithvideoId(tab)) {
          tab.videos = tab.videos.filter((obj) => {
            return obj.videoId?.length;
          });
          returnTabs.push(tab);
        }
      }
      if (this.hasTabHeaders(returnTabs)) {
        returnTabs = returnTabs.filter((obj) => {
          return obj.tabTitle?.length;
        });
      }
      return returnTabs;
    },
    currentMediaItem() {
      if (this.tabs[this.activeTabIndex]) {
        return this.tabs[this.activeTabIndex].videos[this.currentVideoIndex];
      }
      return null;
    },
  },
  methods: {
    hasImagesWithvideoId(tab) {
      return tab.videos.some((el) => el.videoId);
    },
    hasTabHeaders(tabs) {
      return tabs.some((el) => el.tabTitle);
    },
    formatSliderObject(item) {
      const returnedImageArray = [];
      if (item) {
        returnedImageArray.push({
          sourcesThumbnails: [
            { srcset: item.lpImageGridLandscape.srcsetWebp, type: 'image/webp' },
            { srcset: item.lpImageGridLandscape.srcset },
          ],
          videoId: item.videoId || false,
          title: item.title,
        });
      }
      return returnedImageArray;
    },
    playMainVideo() {
      this.showMainVideo = true;
    },
    setTab(tab, tabIdentifier) {
      this.activeTabIndex = tabIdentifier;
      this.showMainVideo = false;
      this.amountOfVideos = this.tabs[tabIdentifier].videos.length;
      this.currentVideoIndex = 0;
      this.recomputeThumbnailWidths();
    },
    truncateHeader(headerText, hasCaption = false) {
      if (hasCaption && headerText.length > 50) {
        return headerText.substring(0, 50) + '...';
      } else if (headerText.length > 100) {
        return headerText.substring(0, 100) + '...';
      } else {
        return headerText;
      }
    },
    truncateCaption(captionText, characterLength) {
      if (captionText.length > characterLength) {
        return captionText.substring(0, characterLength) + '...';
      } else {
        return captionText;
      }
    },
    buttonClickedIncrementCount() {
      this.showVideosMultiplier++;
    },
    recomputeThumbnailWidths() {
      this.$nextTick(() => {
        const activeTab = this.$refs.videoGallery[this.activeTabIndex];
        if (activeTab.slideTotal > 1) {
          activeTab.reloadThumbnails();
        }
      });
    },
    updateCurrentVideoIndex(videoIndex) {
      this.showMainVideo = false;
      this.currentVideoIndex = videoIndex;
    },
    updateSlider(event, index) {
      this.$refs.videoGallery[0].goTo(index);
    },
  },
  created() {
    this.amountOfVideos = this.tabs[this.activeTabIndex].videos.length;
  },
};
</script>

<style lang="scss">
.c_gallery_video {
  $self: &;
  /*display: flex;*/
  /*flex-flow: column nowrap;*/
  padding-top: px_to_rem(80);
  padding-bottom: px_to_rem(80);
  @at-root #{$self}__tabs {
    overflow: hidden;
    width: 100%;
    order: 2;
    --wrapper-padding-right: 0;
    @include mq($mq_gallery_video, '', 'rem') {
      order: 1;
      --wrapper-padding-left: 0;
    }
  }
  @at-root #{$self}__videos {
    order: 1;
    margin-bottom: px_to_rem(25);
    @include mq($mq_sm_to_md, '', 'rem') {
      order: 2;
      margin-bottom: 0;
    }
    @at-root #{&}__main {
      /*flex-direction: column;*/
      box-shadow: px_to_rem(3) px_to_rem(3) px_to_rem(8) rgba($color_black_raw, 0.16);
      @at-root #{&}__image {
        padding-top: 56.25%;
        position: relative;
        .c_image_bg {
          position: absolute;
        }
        @at-root #{&}__play_icon {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          width: 100%;
          color: $color_white;
          $play_icon: &;
          z-index: 25;
          .c_icon_svg {
            position: relative;
            z-index: 10;
          }
          @at-root #{&}__mask {
            opacity: 0;
            transition: opacity 0.5s ease, background 0.5s ease;
          }
          &:hover,
          &:focus {
            color: $color_yellow;
            #{$play_icon}__mask {
              opacity: 1;
              background: rgba($color_black_raw, 0.4);
            }
          }
        }
      }
      @at-root #{&}__info {
        display: grid;
        grid-template-columns: auto;
        grid-gap: px_to_rem(50);
        align-items: center;
        text-align: left;
        background: $color_white;
        min-height: px_to_rem(70);
        padding: px_to_rem(15) px_to_rem(25);
        margin-bottom: px_to_rem(20);
        box-sizing: border-box;
        @include mq($mq_sm_to_md, '', 'rem') {
          grid-template-columns: auto px_to_rem(90);
        }
        @include mq($mq_md_to_lg, '', 'rem') {
          margin-bottom: 0;
        }
        @include mq('800px', '', 'rem') {
          grid-gap: px_to_rem(100);
        }
        @at-root #{&}__descriptor {
          display: none;
          text-align: right;
          color: $color_gray_191;
          @include mq($mq_sm_to_md, '', 'rem') {
            display: block;
          }
        }
      }
    }
    @at-root #{&}__slider {
      .agile__slides {
        padding: px_to_rem(10) 0;
      }
      .c_slider__slide--thumbnail {
        &.agile__slide--active > div {
          border-bottom: 0; // cancel out active state styling from slider component thumbnails. Will revisit after design discussions.
        }
      }
      @at-root #{&}__slide {
        $slide: &;
        @at-root #{&}__content {
          width: 100%;
          height: 100%;
          box-shadow: px_to_rem(3) px_to_rem(3) px_to_rem(6) rgba($color_black_raw, 0.16);
          background: $color_white;
          position: relative;
          @at-root #{&}__image {
            height: 52%;
            @at-root #{&}__mask {
              opacity: 0;
              position: absolute;
              z-index: 50;
              top: 0;
              left: 0;
              width: 100%;
              height: 52%;
              transition: opacity 0.5s ease;
              background: rgba($color_black_raw, 0.4);
            }
          }
          @at-root #{&}__info {
            color: $color_black;
            padding: px_to_rem(15) px_to_rem(15) px_to_rem(15);
            @at-root #{&}__header {
              font-size: 0.9rem;
              line-height: 1.4;
            }
            @at-root #{&}__subhead {
              --text-fz: 0.9rem;
              line-height: 1.6;
            }
          }
        }
        &:hover,
        &:focus {
          #{$slide}__content {
            box-shadow: px_to_rem(3) px_to_rem(3) px_to_rem(6) rgba($color_black_raw, 0.4);
          }
          #{$slide}__content__image__mask {
            opacity: 1;
          }
        }
      }
    }
  }
  @at-root #{$self}__listing {
    order: 3;
    padding-top: px_to_rem(10);
    @at-root #{&}__item {
      $item: &;
      width: 100%;
      padding-bottom: px_to_rem(8);
      margin-bottom: px_to_rem(15);
      border-bottom: 1px solid $color_gray_247;
      outline: initial;
      &:last-of-type {
        border-bottom: 0;
      }
      @include mq($mq_gallery_video, '', 'rem') {
        margin-bottom: px_to_rem(8);
      }
      @at-root #{&}__grid {
        display: grid;
        grid-template-columns: px_to_rem(80) 1fr;
        grid-gap: px_to_rem(20);
        align-items: center;
        @include mq($mq_gallery_video, '', 'rem') {
          grid-gap: px_to_rem(40);
          grid-template-columns: 30% 1fr;
        }
      }
      .c_box--gray_247 & {
        border-bottom: 1px solid $color_white;
      }
      @at-root #{&}__image {
        min-height: px_to_rem(100);
        grid-column: 1;
        grid-row: 1;
        @at-root #{&}__mask {
          grid-column: 1;
          grid-row: 1;
          opacity: 0;
          height: 100%;
          width: 100%;
          transition: opacity 0.5s ease;
          z-index: 50;
          position: relative;
          .c_box--mask {
            background: rgba($color_black_raw, 0.4);
          }
        }
      }
      @at-root #{&}__info {
        grid-column: 2;
        grid-row: 1;
        padding: px_to_rem(15) 0;
        --text-fz: 0.9rem;
      }
      @at-root #{&}__header {
        margin-bottom: px_to_rem(5);
      }
      &:hover,
      &:focus {
        #{$item}__image__mask {
          opacity: 1;
        }
      }
    }
  }
}
</style>
