<template>
  <section class="c_page_header c_wrapper_2022">
    <RelativeAnchor :id="data.pageTitle" v-if="data.displayInHeaderQuicklinks" />
    <h1 class="c_2022--h_cl c_2022--h1_schnyder">
      <span>{{ data.pageTitle }}</span>
      <div class="c_page_header__divider" role="presentation"></div>
    </h1>
    <p :class="data.leadParagraph ? 'c_2022--lead' : 'c_2022--body'" v-if="data.subtitle">{{ data.subtitle }}</p>
  </section>
</template>

<script>
export default {
  components: {
    RelativeAnchor: () => import('Components/relative_anchor/RelativeAnchor.vue'),
  },
  props: {
    data: Object,
  },
};
</script>

<style lang="scss">
.c_page_header {
  $self: &;
  text-align: center;
  padding: px_to_rem(45) px_to_rem(20);
  @include mq($mq_sm_to_md) {
    padding: px_to_rem(30) px_to_rem(20);
  }
  h1 {
    position: relative;
    padding: 0 px_to_rem(20);
    @include mq($mq_sm_to_md) {
      padding: 0 px_to_rem(40);
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      position: relative;
      z-index: 2;
      padding: 0 px_to_rem(10);
      @include mq($mq_sm_to_md) {
        padding: 0 px_to_rem(20);
      }
      background-color: $color_white;
      display: inline-block;
    }
  }
  p {
    margin-top: px_to_rem(25);
  }
  @at-root #{&}__divider {
    z-index: 1;
    height: px_to_rem(1.5);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    background-color: $color_yellow;
  }
}
.lp__block_2023.lp__block--pageHeader {
  padding: 0;
}

.custom-ul {
  color: white;
  list-style: disc;
  list-style-position: outside;
  padding-left: 16px;
}

.custom-ul li {
  color: white;
  list-style: disc;
  list-style-position: outside;
  text-indent: 0px;
}

.red-text {
  color: red;
}
</style>
